import {useEffect, useState} from "react";
import axios from "../../../../../config/Axios";

import User from "./User";
import Button from "../../Components/Button/Button";
import Classes from "./MembersGrid.module.scss";

const MembersGrid = ({version, isMobile}) => {
    const [list, setList] = useState([]);
    const MEMBER_COUNT = [1, 2].includes(version) ? 6 : 12;

    useEffect(() => {
     const getUserList = () => {
            axios.get('/landings/yachts?limit=12')
                .then(function (response) {
                    setList(response.data.users.slice(0, MEMBER_COUNT));
                });
        }
        getUserList();
    }, []);

    return (
        <div className={[Classes.Container, Classes.cFlex, Classes.cFlexColumn].join(' ')}>
            {[1, 2].includes(version) && <div className={[Classes.LinkSlogan].join(' ')}>
                Travellers who are ready for adventures
                {isMobile && <br/>}
                <a href="/members/girls">See all</a>
            </div>}
            {[3, 4].includes(version) && <div className={Classes.LinkSloganAlternate}>
                Travellers who are ready for adventures
                {isMobile && <br/>}
                <a href="/members/girls">See all</a>
            </div>}
            {list.length > 0 && (
                <div className={[Classes.UsersArea, Classes.cFlex, Classes.cFlexSpaceEvenly, Classes.cFlexWrap].join(' ')}>
                    {list.map(user => <User key={user.id} user={user}/>)}
                </div>
            )}

            {[1, 2].includes(version) && <div className={[Classes.Title, Classes.cFlex, Classes.cFlexColumn].join(' ')}>
                <h2>
                    {version !== 2 ? 'Find your perfect match & elevate your holidays' : 'Become part of the community'}
                    <br/>
                    <span className={Classes.italic}>Simply better when shared.</span>
                </h2>
                <Button>Join Travelfriend</Button>
            </div>}
        </div>
    );
}

export default MembersGrid;
