import {useDispatch} from "react-redux";
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import {modalActions} from "../../../store/modal";
import classes from './LandingGayMobile.module.scss';

import MenList from './MenList';
import FacebookLogin from "../../../components/UI/FacebookLogin/FacebookLogin";

import tfLogo from '../../../assets/logo-travelfriend-colored.svg';
import landingImg from './assets/background-mob.jpg';
import landingWebp from './assets/background-mob.webp';
import GoogleLoginButton from "../../../components/GoogleLoginButton";
import {flavor} from "../../../config/Flavor";
import videoImg from "./assets/video.jpg";


const LandingGayMobile = () => {
    const dispatch = useDispatch();

    return (
        <div className={classes.Landing}>
            <div className={classes.MobileHeader}>
                <div className={classes.HeaderLogo}>
                    <img src={tfLogo} alt="Travelfriend" />
                </div>
                <h1 className={classes.HeaderTitle} dangerouslySetInnerHTML={{__html: flavor.title}}></h1>
                <h4>Travelfriend connects thousands of solo travelers across the world.</h4>
            </div>
            <div className={classes.MainBackgroundAndSlogan}>
                <picture>
                    <source srcSet={landingWebp} type="image/webp" />
                    <img src={landingImg} className={classes.LandingImg} alt={'Main background'} />
                </picture>

                <div className={classes.Login}>
                    <GoogleLoginButton />
                    <FacebookLogin />
                    <div className={classes.Or}>or</div>
                    <div className={classes.AuthActions}>
                        <button onClick={() => dispatch(modalActions.open({name: 'registration'}))} className={classes.AuthButton}>Register</button>
                        <span className={classes.Spacer}></span>
                        <button onClick={() => dispatch(modalActions.open({name: 'login', data: {openLogin: true} }))} className={[classes.AuthButton, classes.AuthButtonGrey].join(' ')}>Log in</button>
                    </div>
                </div>
                <h2 className={[classes.MobileOnly, classes.Padding].join(' ')}>Why You Should Join {flavor.name}</h2>
                <p className={[classes.SimpleText, classes.MobileOnly, classes.Padding].join(' ')}>Find friends from all around the world.<br />
                Get an opportunity to visit your dream country.<br />
                Guide people in your own town.<br />
                Meet your soulmate and share your trips together.</p>

                <p className={[classes.SubTitle, classes.MobileOnly, classes.Padding].join(' ')}><b>So, what are you waiting for?
                Join now and dive into your next adventure!</b></p>

                <div className={[classes.MobileOnly, classes.Center, classes.VideoContainer].join(' ')}>
                    <button onClick={() => dispatch(modalActions.open({name: 'registration'}))}>
                        <img src={videoImg} className={classes.videoPlayImg} alt={'Play Video'} />
                        <div className={classes.VideoPlayBtn}></div>
                    </button>
                </div>

                <h3 className={[classes.MobileOnly, classes.Padding].join(' ')}>About {flavor.name}</h3>
                <p className={[classes.SubTitle, classes.MobileOnly, classes.Padding].join(' ')}><b>{flavor.name} started in 2010 and quickly grew
                    to over three million members today.</b></p>

                <p className={[classes.SimpleText, classes.MobileOnly, classes.Padding].join(' ')}>You are backed by a professional and proactive
                customer service.
                    {flavor.name} is a secure and lively travel-dating
                venue for you to enjoy.</p>
            </div>

            <LazyLoadComponent >
                <MenList />
            </LazyLoadComponent>
        </div>
    )
}

export default LandingGayMobile;
