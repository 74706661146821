import React from 'react';
import googlePlayBadge from "../../assets/google-play-badge.jpg";
import {googlePlayURL, googlePlayURLTf} from "../../config/Config";
import {isTravelGirls} from "../../config/Flavor";

const GooglePlayButton = ({className, style}) => (
    <a href={isTravelGirls? googlePlayURL:googlePlayURLTf} target="_blank">
        <img src={googlePlayBadge} className={className} style={style} alt="Download from Google Play"/>
    </a>
);

export default GooglePlayButton;
