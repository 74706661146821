import React, {useState} from 'react';
import {parseInt} from 'lodash';
import MuiSlider from "./MuiSlider";

const SLIDER = {
    MIN: 1,
    MAX: 1000,
};

const FIRST_TIER = 500;
const SECOND_TIER = 400;
const THIRD_TIER = SLIDER.MAX - FIRST_TIER - SECOND_TIER;

const CustomSlider = ({from, to, onChange, type = 'age'}) => {
    let MIN_VALUE, MAX_VALUE, FIRST_TIER_VALUE, SECOND_TIER_VALUE;
    if (type === 'age') {
        MIN_VALUE = 18;
        FIRST_TIER_VALUE = 30;
        SECOND_TIER_VALUE = 60;
        MAX_VALUE = 99;
    } else if (type === 'height') {
        MIN_VALUE = 150;
        FIRST_TIER_VALUE = 180;
        SECOND_TIER_VALUE = 190;
        MAX_VALUE = 220;
    }

    const mapParameterToSliderValue = parameter => {
        parameter = parseInt(parameter);
        if (parameter <= FIRST_TIER_VALUE) {
            return Math.round(
                ((parameter - MIN_VALUE) / (FIRST_TIER_VALUE - MIN_VALUE)) * FIRST_TIER,
            );
        } else if (parameter <= SECOND_TIER_VALUE) {
            return Math.round(
                FIRST_TIER +
                ((parameter - FIRST_TIER_VALUE) /
                    (SECOND_TIER_VALUE - FIRST_TIER_VALUE)) *
                SECOND_TIER,
            );
        } else {
            return Math.round(
                FIRST_TIER +
                SECOND_TIER +
                ((parameter - SECOND_TIER_VALUE) / (MAX_VALUE - SECOND_TIER_VALUE)) *
                THIRD_TIER,
            );
        }
    };
    const [values, setValues] = useState([
         mapParameterToSliderValue(from ? from: MIN_VALUE),
         mapParameterToSliderValue(to ? to: MAX_VALUE)
    ])

    const mapSliderValueToParameter = sliderValue => {
        if (sliderValue <= FIRST_TIER) {
            return Math.round(
                MIN_VALUE + (sliderValue / FIRST_TIER) * (FIRST_TIER_VALUE - MIN_VALUE),
            );
        } else if (sliderValue <= FIRST_TIER + SECOND_TIER) {
            return Math.round(
                FIRST_TIER_VALUE +
                ((sliderValue - FIRST_TIER) / SECOND_TIER) *
                (SECOND_TIER_VALUE - FIRST_TIER_VALUE),
            );
        } else {
            return Math.round(
                SECOND_TIER_VALUE +
                ((sliderValue - FIRST_TIER - SECOND_TIER) / THIRD_TIER) *
                (MAX_VALUE - SECOND_TIER_VALUE),
            );
        }
    };

    const handleChange = (data) => {
        setValues(data)
        onChange([mapSliderValueToParameter(data[0]), mapSliderValueToParameter(data[1])])
    }


    return (
        <MuiSlider
            min={SLIDER.MIN}
            max={SLIDER.MAX}
            values={values}
            onScale={(data) => mapSliderValueToParameter(data)}
            onChange={handleChange}
        />
    )

};

export default CustomSlider;
