import {createSlice} from "@reduxjs/toolkit";
import axios from "../config/Axios";

const initialState = {
    initAt: null,
    historyId: null,
    wasMounted: false,
    isLoading: true,
    users: [],
    hasMore: false
}

const favoriteSlice = createSlice({
    name: 'favorites',
    initialState: initialState,
    reducers: {
        toggle(state, action) {
            let userList = [...state.users];
            userList.map(u => {
                if (u.id === action.payload.userId) {
                    u.isFavorited = !(action.payload.isFavorited);
                }

                return u;
            });
            state.users = userList;
        },
        fetch(state) {
            state.isLoading = true;
        },
        set(state, action) {
            state.isLoading = false;
            state.users = [...state.users, ...action.payload.users];
            state.hasMore = action.payload.hasMore;
        },
        reset(state, action) {
            state.historyId = action.payload.historyId;
            state.initAt = new Date().getTime();
            state.isLoading = true;
            state.users = [];
            state.hasMore = false;
        }
    }
});

export const getFavorites = (userId, from = 0, limit = 20) => {
    return async (dispatch) => {
        dispatch(favoritesActions.fetch());
        const list = await axios.get('/favorites/'+userId+'?limit='+limit+'&from='+from);

        let payload = {
            users: list.data.userList.map((item) => item.user),
            hasMore: !!list.data.hasMore
        };

        dispatch(favoritesActions.set(payload));
    }
}

export const toggleFavorites = (connectedId, userId, isFavorited) => {
    return (dispatch) => {
        dispatch(favoritesActions.toggle({userId: userId, isFavorited: isFavorited}));
        axios.get('/favorites/'+connectedId+'/'+userId+'/'+(!isFavorited ? 'add' : 'remove'));
    }
}

export const favoritesActions = favoriteSlice.actions;
export default favoriteSlice.reducer;
