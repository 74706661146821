import classes from './LandingGay.module.scss';

import MenList from './MenList'
import Logo from "./assets/logo.png";
import landingWebpTf from './assets/background.webp';
import landingImgTf from './assets/background.jpg';
import GoogleLoginButton from '../../../components/GoogleLoginButton';
import FacebookLoginButton from '../../../components/UI/FacebookLogin/FacebookLogin';
import videoImg from './assets/video.jpg';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../../store/modal';
import {flavor} from "../../../config/Flavor";
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const LandingGayWeb = ({girlsList, secondGirlsList, tripList}) => {
    const dispatch = useDispatch();

    const onClickAnyLink = () => {
        dispatch(modalActions.open({name: 'registration'}));
    }

    return (
        <div className={classes.Landing}>
            <div className={classes.Header}>
            <div className={classes.HeaderWrapper}>
                <img src={Logo} className={classes.Logo} alt={"Travelfriend Logo"} />
                <button className={`${classes.LoginBtn} ButtonRounded`} onClick={() => dispatch(modalActions.open({name: 'login'}))}>Log in</button>
            </div>
        </div>
            <picture className={classes.WebBackgroundTf}>
                <source srcSet={landingWebpTf} type="image/webp" />
                <img src={landingImgTf} className={classes.LandingImg} alt={'Main background'} />
            </picture>
            

            <div className={classes.LoginWrapper}>
                <div className={classes.Login}>
                    <h1 className={classes.Title}>Join & Invite.<br />Go on a trip together!</h1>
                    <h4>Travelfriend connects thousands of solo travelers across the world.</h4>
                    <GoogleLoginButton />
                    <FacebookLoginButton />
                    <div className={[classes.Center, classes.Or].join(' ')}>or</div>
                    <div className={classes.AuthActions}>
                        <button onClick={() => dispatch(modalActions.open({name: 'registration'}))} className={classes.AuthButton}>Register</button>
                        <button onClick={() => dispatch(modalActions.open({name: 'login', data: {openLogin: true} }))} className={[classes.AuthButton, classes.AuthButtonGrey].join(' ')}>Log in</button>
                    </div>
                </div>
            </div>

            <div className={classes.CenterWrapper}>
                <div className={classes.Row}>
                    <div className={classes.Column}>
                        <h2 className={[classes.Padding].join(' ')}>Why You Should Join {flavor.name}</h2>
                        <p className={[classes.SimpleText, classes.Padding].join(' ')}>Find friends from all around the world.<br />
                        Get an opportunity to visit your dream country.<br />
                        Guide people in your own town.<br />
                        Meet your soulmate and share your trips together.</p>

                        <p className={[classes.SubTitle, classes.Padding].join(' ')}><b>So, what are you waiting for?<br />
                        Join now and dive into your next adventure!</b></p>
                    </div>
                    <div className={classes.Column}>
                        <div className={[classes.Center, classes.VideoContainer].join(' ')}>
                            <button onClick={onClickAnyLink}>
                                <img src={videoImg} className={classes.videoPlayImg} alt={'Play Video'} />
                                <div className={classes.VideoPlayBtn}></div>
                            </button>
                        </div>
                    </div>
                </div>

                <h3 className={[classes.Padding].join(' ')}>About {flavor.name}</h3>
                <p className={[classes.SubTitle, classes.Padding].join(' ')}><b>{flavor.name} started in 2010 and quickly grew
                    to over three million members today.</b></p>

                <p className={[classes.SimpleText, classes.Padding].join(' ')}>You are backed by a professional and proactive
                customer service.<br />
                    {flavor.name} is a secure and lively travel-dating
                venue for you to enjoy.</p>

                <LazyLoadComponent threshold={100}>
                    <MenList />
                </LazyLoadComponent>
            </div>
        </div>
    )
}

export default LandingGayWeb;
