export const isTravelGirls = process.env.REACT_APP_TRAVELFRIEND === undefined || process.env.REACT_APP_TRAVELFRIEND === false;

const flavors = {
    travelgirls: {
        title: 'Meet thousands of adventurous girls who want to travel!',
        name: 'Travelgirls',
        domainName: 'travelgirls.com',
        email:'info@travelgirls.com'
    },
    travelfriend: {
        title: 'Join & Invite.<br />Go on a trip together!',
        name: 'Travelfriend',
        domainName: 'travelfriend.com',
        email:'support@travelfriend.com'

    }
}


export const flavor = flavors[isTravelGirls ? 'travelgirls' : 'travelfriend'];
