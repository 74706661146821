import {useEffect} from "react";
import {useLocation, useNavigationType} from "react-router-dom";


const ScrollToTop = () => {
    const location = useLocation();
    const navType = useNavigationType();

    const scrollToTop = () => {
        if (navType === 'PUSH') {
            window.scrollTo(0, 0);
        }
    }
    useEffect(scrollToTop, [location.pathname, navType]);

    return null;
}

export default ScrollToTop;
