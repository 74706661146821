import { NavLink } from 'react-router-dom';
import {useDispatch} from "react-redux";

import {authActions} from "../../../../store/auth";
import axios from "../../../../config/Axios";
import classes from "../DesktopMenu/DestopMenu.module.scss";

const Notifications = (props) => {
    const dispatch = useDispatch();
    const closeNotification = (notificationId) => {
        axios.post('notifications/close', {id:notificationId}).then((r) => {
            dispatch(authActions.removeNotification({id: notificationId}));
        });
    }

    return (
        <div className={[classes.Menu, classes.NotificationMenu].join(' ')} id="notificationsMenu">
            <div className={classes.ArrowUp}></div>
            <div className={classes.Title}>Notifications</div>

            {props.notifications.map((item) => {
                return (
                    <div className={classes.NotificationItem} key={item.id}>
                        {item.canClose &&
                            <div className={classes.CloseBtn}>
                                <i className="tg-icon icon-close-small" onClick={() => {closeNotification(item.id)}}></i>
                            </div>}
                        <div className={classes.NotificationContent}>{item.text}</div>
                        {item.buttonText &&
                            <NavLink to={item.buttonHref} onClick={props.closeMenu} className={classes.NotificationActions}>{item.buttonText}</NavLink>}
                    </div>
                );
            })}
            {props.notifications.length === 0 && <div className={classes.EmptyContent}>No notifications</div>}
        </div>
    )
}

export default Notifications;

