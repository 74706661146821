import {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";

import {getMessageDraft, saveMessageDraft} from "../../helpers/MessageDraft";
import axios from "../../config/Axios";
import classes from './SendMessage.module.scss';
import {getMainPhotoInMemberProfile} from '../../helpers/Photos';
import imgVerified from '../../assets/verified-green.svg';
import { validateEmail } from '../../helpers/Validators';


const SendMessageUnregistered = () => {
    const user = useSelector(state => state.auth.user);
    const modalUser = useSelector(state => state.modal.data.user);

    const [content, setContent] = useState(getMessageDraft(modalUser.id));
    const [email, setEmail] = useState('');
    const [isSent, setIsSent]  = useState(false);
    const [showEmailPromt, setShowEmailPromt] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailExistsError, setEmailExistsError] = useState(false);

    useEffect(() => {
        saveMessageDraft(modalUser.id, content);
    }, [content]);

    const u = modalUser;
    if (!modalUser) {
        return (<div></div>);
    }

    const showEmailHandler = () => {
        if (content.length === 0) {
            return;
        }
        setShowEmailPromt(true);
    }

    const send = () => {
        if (content.length === 0) {
            return;
        }

        if (email.length === 0 || !validateEmail(email)) {
            setEmailError(true);
            return;
        } else {
            setEmailError(false);
        }

        axios.post('landings/send_message_unregistered', {email: email, message: content, recipient: u.id}).then((r)=> {
            if (r.data.error) {
                setEmailExistsError(true);
                //setTimeout(() => {window.location = '/'}, 2000);
            }

            if (r.data.success) {
                setIsSent(true);
            }
        });
    }


    return (
        <div className={classes.Area}>
            <img src={getMainPhotoInMemberProfile(u, 100)} className={classes.UserImage} alt="User profile" />
            <div className={classes.Name}>
                {modalUser.name}
                {modalUser.sex === 'female' && modalUser.isVerified ? <img className={classes.Verified} src={imgVerified} alt=""/> : null}
            </div>
            {isSent ? <MessageWasSent user={user} /> : (
                <Fragment>
                    <div className={classes.Text}>Write a quick message to catch {modalUser.sex === 'female' ? 'her' : 'his'} interest</div>
                        <div className={classes.AreaActions}>
                        <textarea
                            className={classes.Textarea}
                            placeholder={'Your message...'}
                            value={content}
                            onChange={(e) => setContent(e.target.value)} />
                        <div className={classes.Actions}>
                            {!showEmailPromt && 
                                <button className={classes.SendArea} onClick={showEmailHandler}>
                                    Send <i className="tg-icon icon-send"></i>
                                </button>
                            }
                            {showEmailPromt && 
                                <>
                                    <div className={classes.EmailText}>Please enter the email address you would like to receive a response to.</div>
                                    {emailExistsError && 
                                        <div className={classes.EmailExists}>Email already exists. Please sign in.</div>
                                    }
                                    <input className={emailError ? classes.Error : ''} type="text" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <button className={classes.SubmitBtn} onClick={send}>
                                        Submit
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                </Fragment>)}
        </div>
    );
};

const MessageWasSent = () => (
    <>
        <div className={classes.Text}>Message was sent successfully!</div>
        <div className={classes.SuccessText}>Please check your mailbox to confirm email</div>
    </>
);

export default SendMessageUnregistered;
