import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    list: [],
    isLoading: true,
}

const myTripsSlice = createSlice({
    name: 'myTrips',
    initialState: initialState,
    reducers: {
        set(state, action) {
            state.isLoading = false;
            state.list = action.payload.trips;
        },
        add(state, action) {
            state.list = state.list.concat(action.payload.trip);
        },
        delete(state, action) {
            state.list = state.list.filter(l => l.id !== action.payload.tripId);
        }
    }
});

export const myTripsActions = myTripsSlice.actions;
export default myTripsSlice.reducer;
