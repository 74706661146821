import {isTravelGirls} from "./Flavor";

export const DOMAIN_NAME = isTravelGirls ? 'Travelgirls.com': 'Travelfriend.com';

export const titles = {
    '/': DOMAIN_NAME + ' - #1 Travel dating site since 2010. Find your perfect travel partner!',
    '/conversations': DOMAIN_NAME + ' - Conversations',
    '/members': DOMAIN_NAME + ' - Browse Members',
    '/members/girls': DOMAIN_NAME + ' - Browse Girls',
    '/members/men': DOMAIN_NAME + ' - Browse Men',
    '/visitors': DOMAIN_NAME + ' - Profile visitors',
    '/favorites': DOMAIN_NAME + ' -  Favorites',
    '/trips': DOMAIN_NAME + ' - Trips',
    '/support': DOMAIN_NAME + ' - Support',
    '/tips': DOMAIN_NAME + ' - Travel tips',
    '/not-found': DOMAIN_NAME + ' - Page not found',
    '/faq': DOMAIN_NAME + ' FAQ',
    '/about-us': DOMAIN_NAME + ' - About us',
    '/sugar-baby-atlanta': 'Sugar Baby Waiting - Atlanta | ' + DOMAIN_NAME,
    '/sugar-baby-new-york': 'Sugar Baby Waiting - New York | ' + DOMAIN_NAME,
    '/sugar-baby-miami': 'Sugar Baby Waiting - Miami | ' + DOMAIN_NAME,
    '/sugar-baby-boston': 'Sugar Baby Waiting - Boston | ' + DOMAIN_NAME,
    '/sugar-baby-austin': 'Sugar Baby Waiting - Austin | ' + DOMAIN_NAME,
    '/sugar-baby-los-angeles': 'Sugar Baby Waiting - Los Angeles | ' + DOMAIN_NAME,
    '/sugar-baby-portland': 'Sugar Baby Waiting - Portland | ' + DOMAIN_NAME,
    '/sugar-baby-denver': 'Sugar Baby Waiting - Denver | ' + DOMAIN_NAME,
    '/sugar-baby-chicago': 'Sugar Baby Waiting - Chicago | ' + DOMAIN_NAME,
    '/sugar-baby-seattle': 'Sugar Baby Waiting - Seattle | ' + DOMAIN_NAME,
    '/sugar-baby-nashville': 'Sugar Baby Waiting - Nashville | ' + DOMAIN_NAME,
    '/sugar-baby-houston': 'Sugar Baby Waiting - Houston | ' + DOMAIN_NAME,
    '/sugar-baby-philadelphia': 'Sugar Baby Waiting - Philadelphia | ' + DOMAIN_NAME,
    '/sugar-baby-dallas': 'Sugar Baby Waiting - Dallas | ' + DOMAIN_NAME
}

export const metaDescription = {
    '/': 'Join our community and meet successful people from all over the world! #1 Travel dating site since 2010.',
    '/trips': '',
    '/members': 'Do you like to travel? Get in touch with people who share the same interest!',
    '/members/girls': 'Do you like to travel? Get in touch with people who share the same interest!',
    '/members/men': 'Do you like to travel? Get in touch with people who share the same interest!',
    '/escort': 'Stop Paying For Escorts In 210 Countries - Meet Travel Girls!',
    '/sugar-daddy-dating': 'Sugar Daddy Dating In 150 Countries',
    '/sugar-baby-atlanta': 'When it comes to finding a sugar baby, Atlanta is one of the highly preferred places among travellers.',
    '/sugar-baby-new-york': 'When it comes to finding a sugar baby, New York is one of the highly preferred places among travellers.',
    '/sugar-baby-miami': 'When it comes to finding a sugar baby, Miami is one of the highly preferred places among travellers.',
    '/sugar-baby-boston': 'When it comes to finding a sugar baby, Boston is one of the highly preferred places among travellers.',
    '/sugar-baby-austin': 'When it comes to finding a sugar baby, Austin is one of the highly preferred places among travellers.',
    '/sugar-baby-los-angeles': 'When it comes to finding a sugar baby, Los Angeles is one of the highly preferred places among travellers.',
    '/sugar-baby-portland': 'When it comes to finding a sugar baby, Portland is one of the highly preferred places among travellers.',
    '/sugar-baby-denver': 'When it comes to finding a sugar baby, Denver is one of the highly preferred places among travellers.',
    '/sugar-baby-chicago': 'When it comes to finding a sugar baby, Chicago is one of the highly preferred places among travellers.',
    '/sugar-baby-seattle': 'When it comes to finding a sugar baby, Seattle is one of the highly preferred places among travellers.',
    '/sugar-baby-nashville': 'When it comes to finding a sugar baby, Nashville is one of the highly preferred places among travellers.',
    '/sugar-baby-houston': 'When it comes to finding a sugar baby, Houston is one of the highly preferred places among travellers.',
    '/sugar-baby-philadelphia': 'When it comes to finding a sugar baby, Philadelphia is one of the highly preferred places among travellers.',
    '/sugar-baby-dallas': 'When it comes to finding a sugar baby, Dallas is one of the highly preferred places among travellers.',
}
