import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import { SHOW_PREFERENCES } from '../../config/Constants';
import ImgMale from '../../assets/preferences/icon-man.svg';
import ImgFemale from '../../assets/preferences/icon-woman.svg';
import CustomSlider from "../Slider/CustomSlider";
import classes from './Preferences.module.scss';
import {updateUser} from "../../store/auth";
import {galleryActions} from "../../store/gallery";
import { aggGalleryUrl } from '../../helpers/UserUrl';

const Preferences = ({modalClosed}) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const [filter, setFilter] = useState(null);

    useEffect(() => {
        setFilter(user.galleryFilter);
        localStorage.removeItem(SHOW_PREFERENCES);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const toggleGender = (gender) => {
        let genderList = [...filter.sexList];
        if (genderList.includes(gender)) {
            genderList = genderList.filter(function(item) {
                return item !== gender
            });
        }
        else {
            genderList.push(gender);
        }
        setFilter({...filter, sexList: genderList});
    }

    const changeAgeFilter = (data) => {
        setFilter({...filter, age: {from: data[0], to: data[1]}});
    }

    const saveFilter = () => {
        dispatch(updateUser(user.id, {galleryFilter: filter}));
        if (filter !== user.galleryFilter) {
            dispatch(galleryActions.reset());
        }
        if (filter.sexList !== user.galleryFilter.sexList) {
            let newUserFilter = {galleryFilter : filter};
            window.location = aggGalleryUrl(newUserFilter);
        }
        modalClosed();
    }

    const classListMale = [classes.Circle];
    const classListFemale = [classes.Circle];

    if (!filter) {
        return null;
    }

    if (filter.sexList.includes('male')) {
        classListMale.push(classes.Checked);
    }

    if (filter.sexList.includes('female')) {
        classListFemale.push(classes.Checked);
    }

    return (
        <div className={classes.Pref}>
            <h1>I prefer to travel with ...</h1>
            <div className={classes.Introduction}>Adjust your preferences to see and be<br/> seen by those who fit them.</div>

            <div className={classes.Genders}>
                <button className={classes.Option} onClick={() => toggleGender('male')}>
                    <div>
                        <img src={ImgMale} alt="Preferred male gender"/>
                        <div className={classes.CheckBoxArea}>
                            <div>Male</div>
                            <div className={`${classes.Checkbox} ${classes.Male}`}>
                                <div className={classListMale.join(' ')}><i className="tg-icon icon-tick"></i></div>
                            </div>
                        </div>
                    </div>
                </button>
                <button className={classes.Option} onClick={() => toggleGender('female')}>
                    <div>
                        <img src={ImgFemale} alt="Preferred female gender"/>
                        <div className={classes.CheckBoxArea}>
                            <div>Female</div>
                            <div className={classes.Checkbox}>
                                <div className={classListFemale.join(' ')}><i className="tg-icon icon-tick"></i></div>
                            </div>
                        </div>
                    </div>
                </button>
            </div>
            <div className={classes.AgeTitle}>Age</div>
                <CustomSlider from={filter.age.from} to={filter.age.to} type='age' onChange={changeAgeFilter} />
            <div className={classes.SaveArea}>
                <button className={classes.Save} onClick={saveFilter}>Save preferences</button>
            </div>
        </div>
    );
}

export default Preferences;
