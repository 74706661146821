import {Fragment} from "react";

import classes from './GirlsList.module.scss';
import Loader from '../../../../components/UI/Loader/Loader';
import {getCountryName} from '../../../../helpers/Countries';
import aggPhoto from '../../../../helpers/Photos';
import arrowImg from '../assets/arrow.png';

const GirlsInline = ({list, onShowRegistration, showBackBtn}) => {
    return (
        <div className={classes.GirlsInlineWrapper}>
            {list === null
                ? <Loader/>
                : (
                    <Fragment>
                        {showBackBtn && <div className={classes.ArrowWrapper} onClick={onShowRegistration}><i className="tg-icon icon-tick-back"></i></div>}
                        <div className={classes.List}>
                            {list.map((item, id) => {                                
                                return (
                                    <div onClick={onShowRegistration} key={id} className={classes.User}>
                                        <img src={aggPhoto(item.photo, 200)} alt={''}/>
                                        <div className={classes.AreaName}>
                                            <span className={classes.Name}>{item.name}</span>
                                        </div>
                                        <div className={classes.Country}><div className={classes.CountryName}>{getCountryName(item.countryCode)}</div><img className={classes.Arrow} src={arrowImg} alt="arrow" /> Dubai</div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className={classes.ArrowWrapper} onClick={onShowRegistration}><i className="tg-icon icon-tick-right"></i></div>
                    </Fragment>
                )
            }
        </div>
    );
}

export default GirlsInline;
