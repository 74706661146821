import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

import classes from "./DestopMenu.module.scss";
import {modalActions} from "../../../../store/modal";
import {updateUser} from "../../../../store/auth";
import IosSwitch from "../../../../components/UI/Filters/IosSwitch/IosSwitch";

const DesktopMenu = (props) => {
    const dispatch = useDispatch();
    const nav = useNavigate();

    const navigate = (to) => {
        props.closeMenu();
        nav(to);
    }

    const openPopup = (modalName) => {
        props.closeMenu();
        dispatch(modalActions.open({name: modalName}));
    }

    const onOpenGroupMessage = () => {
        if (props.user.additional.groupMsg.canCreate || props.user.additional.groupMsg.canEdit) {
            navigate('/group-message');
        } else {
            navigate('/membership');
        }
    }

    const isRegisteredMale = (
        props.user.sex === 'male' &&
        !props.user.membership.isPremium &&
        props.user.additional.prices.plan === 'default'
    );
    const isDisabledClass = (isRegisteredMale) ? classes.IsDisabled : '';

    const changeVisibility = (val) => {
        if (props.user.membership.isDiamond) {
            dispatch(updateUser(props.user.id, {isInvisible: val}));
        }
    }

    return (
        <div className={classes.Menu} id="desktopMenu">
            <div className={classes.ArrowUp}></div>
            <button onClick={() => openPopup('preferences')}>
                <span className="tg-icon icon-search"></span> Search Preferences
            </button>
            <button onClick={() => navigate('/edit')}>
                <span className="tg-icon icon-profile-background"></span> Profile
            </button>
            <button onClick={() => navigate('/membership')}>
                <span className="tg-icon icon-diamond"></span> Membership
                {props.user.sex === 'male' && props.user.membership.isDiamond && <span className={classes.NewItemGold}>VIP</span>}
            </button>
            {props.user.additional.groupMsg.show &&
                <button onClick={onOpenGroupMessage}>
                    <span className="tg-icon icon-group-profile"></span> Group message
                </button>
            }
            {props.user.membership.isDiamond &&
                <button onClick={() => navigate('/hide-profile')}>
                    <span className="tg-icon icon-trips-map"></span> Hide my profile<span className={classes.NewItem}>NEW</span>
                </button>
            }
            { (isRegisteredMale || (props.user.sex === 'male' && props.user.membership.isDiamond)) &&
                <div className={`${classes.Line} ${isDisabledClass}`}>
                    <i className="tg-icon icon-hide"></i>
                    Browse anonymous
                    {isRegisteredMale && <span className={classes.NewItemGold}>VIP</span>}
                    <span className={classes.Toggle}><IosSwitch isSelected={props.user.isInvisible} isDisabled={isRegisteredMale} onSwitch={(val) => changeVisibility(val)} /></span>
                </div>
            }
            <button onClick={() => navigate('/logout')}>
                <span className="tg-icon icon-logout"></span> Logout
            </button>
        </div>
    );
}

export default DesktopMenu;
