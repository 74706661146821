import {Fragment} from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";


import classes from './Layout.module.scss';
import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import MenuMobile from './MenuMobile/MenuMobile';
import MainContent from './MainContent/MainContent';
import Footer from './Footer/Footer';
import Gdpr from "./Gdpr/Gdpr";


const Layout = (props) => {
    const location = useLocation();
    const auth = useSelector(state => state.auth);
    const headerAlwaysVisible = !!(props.headerAlwaysVisible);
    const isVisibleSidebar = (!props.hideSidebar);
    const hideMainContentSpacer = !!(props.hideMainContentSpacer);
    const hideFooter = !!(props.hideFooter);
    const fullWidth = !!(props.fullWidth);
    const newMembersInSidebar = !!(props.newMembersInSidebar);
    let mobileMenuVisible = (props.mobileMenuVisible !== false);

    // Workaround to hide menu inside-conversation
    if (mobileMenuVisible && auth.forceHideMobileMenu) {
        mobileMenuVisible = false;
    }

    // Redirect all unauthorised paths
    if (props.authRequired && auth.fetchOnInit && !auth.user) {
        return <Navigate to={'/?session=expired'} replace={true} />
    }

    // Check for suspended user
    if (auth.user && auth.user.isSuspended
        && location.pathname !== '/suspended'
        && location.pathname !== '/conversations/23311'
        && location.pathname !== '/logout') {
            return <Navigate to={'/suspended'} replace={true} />
    }

    const classesLayout = [classes.Layout];
    return (
        <Fragment>
            <Header headerAlwaysVisible={headerAlwaysVisible} />
            <div className={classesLayout.join(' ')}>
                <div className={classes.DesktopSidebarContentWrapper}>
                    {isVisibleSidebar ? <Sidebar newMembersInSidebar={newMembersInSidebar} /> : null}
                    <MainContent
                        isVisibleSidebar={isVisibleSidebar}
                        hideMainContentSpacer={hideMainContentSpacer}
                        fullWidth={fullWidth}
                    >{props.children}</MainContent>
                </div>
                <Gdpr />
                {!hideFooter && <Footer />}

                {mobileMenuVisible && <MenuMobile />}
                {mobileMenuVisible && <div className="BottomSpacer" />}
            </div>
        </Fragment>
    );
}

export default Layout;
