import {Fragment} from "react";

import classes from './GirlsList.module.scss';
import Loader from '../../../../components/UI/Loader/Loader';
import {getCountryName} from '../../../../helpers/Countries';
import aggPhoto from '../../../../helpers/Photos';
import arrowImg from '../assets/arrow.png';
import { aggTripDate } from "../../../../helpers/Dates";

const GirlsTrips = ({list, onShowRegistration}) => {
    return (
        <div className={classes.GirlsInlineWrapper}>
            {list === null
                ? <Loader/>
                : (
                    <Fragment>
                        <div className={classes.ArrowWrapper} onClick={onShowRegistration}><i className="tg-icon icon-tick-back"></i></div>
                        <div className={classes.List}>
                            
                            {list.map((item, id) => {
                                return (
                                    <div onClick={onShowRegistration} key={id} className={classes.User}>
                                        <img src={aggPhoto(item.user.photo, 200)} alt={''}/>
                                        <div className={classes.AreaName}>
                                            <span className={classes.Name}>{item.user.name}</span>
                                        </div>
                                        <div className={classes.Country}><div className={classes.CountryName}>{getCountryName(item.user.location.countryCode)}</div><img className={classes.Arrow} src={arrowImg} alt="arrow" /> Dubai</div>
                                        {item.date && <div className={classes.Date}>{aggTripDate(item.date.from)} - {aggTripDate(item.date.to)}</div>}
                                    </div>
                                )
                            })}
                            
                        </div>
                        <div className={classes.ArrowWrapper} onClick={onShowRegistration}><i className="tg-icon icon-tick-right"></i></div>
                    </Fragment>
                )
            }
        </div>
    );
}

export default GirlsTrips;
