import {createSlice} from "@reduxjs/toolkit";
import axios from "../config/Axios";

const initialState = {
    historyId: null,
    isLoading: true,
    users: [],
    hasMore: false,
    location: null,
    params: {
        personalLimit: 20,
        personalFrom: 0,
        getPersonal: 0,
        getAll: 1,
        limit: 20,
        from: 0
    }
}

const tripsSlice = createSlice({
    name: 'trips',
    initialState: initialState,
    reducers: {
        set(state, action) {
            let userList = [...state.users, ...action.payload.users];
            state.isLoading = false;
            state.users = userList;
            state.hasMore = action.payload.hasMore;
            state.params.from = userList.length;
            state.params.personalFrom = userList.length;
        },
        reset(state, action) {
            return {
                ...initialState, ...action.payload
            }
        },
        startToFetch(state) {
            state.isLoading = true;
        }
    }
});

export const fetchTrips = (userId = null, options) => {
    return (dispatch, getState) => {
        if (options.isNew) {
            dispatch(tripsActions.reset(options.data));
        }

        dispatch(tripsActions.startToFetch());
        const state = getState().trips;
        const params = {...state.params};

        if (state.location && state.location.locationCountryCode) {
            params['countryCode'] = state.location.locationCountryCode;
            if (state.location.locationType === 'locality') {
                params['locationId'] = state.location.locationId;
            }
        }

        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');

        const userIdParam = (!userId) ? '' : userId;
        axios.get('/trips/get/'+userIdParam+'?'+queryString)
        .then(r => {
            dispatch(tripsActions.set({
                users: (params.getPersonal === 1) ? aggUsers(r.data.personalTrips.list) : aggUsers(r.data.list),
                hasMore: (params.getPersonal === 1) ? !!r.data.personalTrips.hasMore : !!r.data.hasMore
            }));
        });
    }
}

const aggUsers = (list) => {
    return list.map((item) => {
        return {
            ...item.user,
            tripId: item.id,
            _additional: {
                date: {
                    from:item.date.from,
                    to: item.date.to
                },
                description: item.description,
                id: item.id,
                isNew: item.isNew,
                location: item.location
            }
        }
    });
}

export const tripsActions = tripsSlice.actions;
export default tripsSlice.reducer;
