import classes from './Verify.module.css';
import {useNavigate} from "react-router-dom";

const Verify = (props) => {
    const nav = useNavigate();

    const gotoVerify = () => {
        props.modalClosed();
        nav('/verification');
    }

    return (
        <div className={classes.Area}>
            {props.data && props.data.groupMessage
                ? (
                <h3>Only verified members can send a group message.<br />Verify now!</h3>
            ) : (
                <h3>Please verify your account to contact others.</h3>
            )}
            <button onClick={gotoVerify} className={["ButtonRounded", "ButtonRoundedBig"].join(' ')}>Verify</button>
        </div>
    );
}

export default Verify;
