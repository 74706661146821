import {createSlice} from "@reduxjs/toolkit";
import queryString from "query-string";
import axios from "../config/Axios";

const initialFilterState = {
    locationId: null,
    locationName: '',
    locationType: null,
    locationSearchType: null,
    sortBy: null,
    language: null,
    lookingFor: null,
    ageLower: null,
    ageUpper: null,
    name: '',
    eyes: null,
    hair: null,
    heightLower: null,
    heightUpper: null,
    bodyType: null,
    onlyVerified: false,
    onlyPaid: false,
    onlyPrivatePhotos: false
}

const initialState = {
    wasMounted: false,
    isLoading: true,
    users: [],
    hasMore: false,
    filter: initialFilterState,
    view: 'default',
    dubaiUsers: []
}

const gallerySlice = createSlice({
    name: 'gallery',
    initialState: initialState,
    reducers: {
        setFilter(state, action) {
            state.isLoading = true;
            state.filter = action.payload.filter;
            state.users = [];
            state.hasMore = false;
        },
        set(state, action) {
            let currentList = (action.payload.firstLoad) ? [] : state.users;
            let userList = currentList.concat(action.payload.users);
            state.wasMounted = true;
            state.isLoading = false;
            state.users = userList;
            state.hasMore = action.payload.hasMore;

            if (typeof action.payload.view !== 'undefined') {
                state.view = action.payload.view;
            }
            if (typeof action.payload.dubaiUsers !== "undefined") {
                state.dubaiUsers = action.payload.dubaiUsers;
            }
        },
        loadMore(state) {
            state.isLoading = true;
        },
        reset(state) {
            state.wasMounted = false;
            state.isLoading = true;
            state.users = [];
            state.hasMore = false;
        }
    }
});

export const fetchGallery = (newFilter, isNew = true) => {
    return dispatch => {
        dispatch(galleryActions.setFilter({filter: newFilter}));

        axios.post('gallery/gp', newFilter)
            .then(r => {
                dispatch(galleryActions.set(r.data));
            }).catch(error => {
                //dispatch(galleryActions.set({users: [], hasMore: false, firstLoad: true}));
                alert('Failed gallery load. Try refresh the page.');
        });
    }
}

export const loadMore = (userList, filter) => {
    return dispatch => {
        dispatch(galleryActions.loadMore());

        let postParams = {
            ...filter,
            loadedUserIds: (userList.map(u => u.id)),
            firstLoad: false
        }

        axios.post('gallery/gp', postParams)
            .then(r => {
                dispatch(galleryActions.set(r.data));
            }).catch(error => {
            //dispatch(galleryActions.set({users: [], hasMore: false, firstLoad: true}));
            alert('Failed gallery load. Try refresh the page.');
        });
    }
}

export const getFilterFromUrl = (location) => {
    let params =  queryString.parse(location.search);
    let newFilter = {...initialFilterState};

    if (params['locationId']) newFilter.locationId = params['locationId'];
    if (params['locationName']) newFilter.locationName = params['locationName'];
    if (params['locationType']) newFilter.locationType = params['locationType'];
    if (params['locationSearchType']) newFilter.locationSearchType = params['locationSearchType'];

    if (location.pathname === '/members/girls') newFilter.sex = 'girls';
    else if (location.pathname === '/members/men') newFilter.sex = 'men';

    if (params['name'] && params['name'].length > 0) newFilter.name = params['name'];
    if (params['ageLower']) newFilter.ageLower = (params['ageLower']);
    if (params['ageUpper']) newFilter.ageUpper = (params['ageUpper']);
    if (params['heightLower']) newFilter.heightLower = (params['heightLower']);
    if (params['heightUpper']) newFilter.heightUpper = (params['heightUpper']);

    if (params['language']) newFilter.language = params['language'];
    if (params['lookingFor']) newFilter.lookingFor = params['lookingFor'];

    if (params['eyes']) newFilter.eyes = params['eyes'];
    if (params['hair']) newFilter.hair = params['hair'];
    if (params['bodyType']) newFilter.bodyType = params['bodyType'];

    if (params['onlyVerified'] && newFilter.sex === 'girls') newFilter.onlyVerified = true;
    if (params['onlyPaid']) newFilter.onlyPaid = true;
    if (params['onlyPrivatePhotos']) newFilter.onlyPrivatePhotos = true;
    if (params['sortBy']) newFilter.sortBy = params['sortBy'];

    if (params['atw'] === '1') newFilter.allowToWrite = true;

    return newFilter;
}

export const aggregateUrlFromFilter = (filter) => {
    let url = '/members';
    let list = [];

    for (const key in filter) {
        if (key === 'sex' && (filter[key] === 'girls' || filter[key] === 'men')) {
            url += ('/'+filter[key]);
        }

        // Gender is in pathname
        if (key === 'sex') {
            continue;
        }

        // Append these only if they are checked
        if ((key === 'onlyVerified' || key === 'onlyPaid' || key === 'onlyPrivatePhotos') && filter[key] === false) {
            continue;
        }

        if (filter[key] === null || filter[key] === '' || filter[key] === undefined) {
            continue;
        }

        // Skip arrays if they are empty
        if (typeof filter[key] === 'object' && filter[key].length === 0) {
            continue;
        }

        list[key] = (typeof filter[key] === 'object') ? filter[key].join(',') : filter[key];
    }

    return {
        'pathname': url,
        'search':  queryString.stringify(list)
    }
}

export const getInitialFilterState = () => {
    return initialFilterState;
}

export const galleryActions = gallerySlice.actions;
export default gallerySlice.reducer;
