import {Fragment, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { NavLink } from 'react-router-dom';


import {publicDevMode} from "../../../helpers/DeveloperMode";
import classes from './Header.module.scss';
import tgLogo from '../../../assets/travelgirls-logo-coloured.svg';
import tfLogo from '../../../assets/logo-travelfriend-colored.svg';
import {modalActions} from "../../../store/modal";
import { getMainAggregated } from '../../../helpers/Photos';
import { aggGalleryUrl } from '../../../helpers/UserUrl';
import DesktopMenu from "./DesktopMenu/DesktopMenu";
import Notifications from "./Notifications/Notifications";
import BackToState from "./BackToState";
import {isTravelGirls} from "../../../config/Flavor";


const Header = ({headerAlwaysVisible}) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const counters = useSelector(state => state.counters);
    const ntfs = user ? user.additional.notifications : [];

    const [isMenuOpened, setMenuOpened] = useState(false);
    const [isNotificationsOpened, setNotificationsOpened] = useState(false);

    const openNotifications = () => {
        if (ntfs.length === 0) {
            return;
        }

        if (isNotificationsOpened) {
            closeNotifications();
            return;
        }
        setMenuOpened(false);
        setNotificationsOpened(true);
        document.body.addEventListener('click', closeOnClickOutsideListenerNotifications, true);
    }

    const closeNotifications = () => {
        document.body.removeEventListener('click', closeOnClickOutsideListenerNotifications, true)
        setMenuOpened(false);
        setNotificationsOpened(false);
    }
    const closeOnClickOutsideListenerNotifications = (e) => {
        if (document.getElementById('notificationsMenu') && document.getElementById('notificationsOpener')) {
            if (!document.getElementById('notificationsMenu').contains(e.target) &&
                !document.getElementById('notificationsOpener').contains(e.target)) {
                closeNotifications();
            }
        }
    }

    const openMenu = () => {
        setMenuOpened(true);
        setNotificationsOpened(false);
        document.body.addEventListener('click', closeOnClickOutsideListener, true);
    }
    const closeMenu = () => {
        document.body.removeEventListener('click', closeOnClickOutsideListener, true)
        setMenuOpened(false);
        setNotificationsOpened(false);
    }
    const closeOnClickOutsideListener = (e) => {
        if (document.getElementById('desktopMenu') && document.getElementById('menuOpener')) {
            if (!document.getElementById('desktopMenu').contains(e.target) &&
                !document.getElementById('menuOpener').contains(e.target)) {
                closeMenu();
            }
        }
    }

    let userArea = <button className="ButtonRounded" onClick={() => dispatch(modalActions.open({name: 'login'}))}>Login</button>
    const notificationClassList = [classes.NotificationButton];

    if (ntfs.length === 0) {
        notificationClassList.push(classes.NotificationButtonInactive)
    }

    if (isNotificationsOpened) {
        notificationClassList.push(classes.ActiveIcon)
    }

    if (user) {
        userArea = (
            <div className={classes.Right}>
                <NavLink className={({isActive}) => isActive ? [classes.Conversations, classes.ActiveIcon].join(' ') : classes.Conversations} to="/conversations">
                    <i className="tg-icon icon-conversations"></i>
                    {counters.unreadConversations > 0
                        ? <span className={classes.UnreadCounter}>{counters.unreadConversations}</span>
                        : null
                    }
                </NavLink>

                <div className={classes.Notifications}>
                    <button className={notificationClassList.join(' ')} onClick={openNotifications} id="notificationsOpener">
                        <i className="tg-icon icon-bell" style={{fontSize: '22px'}} />
                        {ntfs.length > 0 && <span className={classes.UnreadCounter}>{ntfs.length}</span>}
                    </button>
                    {isNotificationsOpened ? <Notifications notifications={ntfs} closeMenu={closeNotifications} /> : null}
                </div>

                <button className={classes.User} onClick={openMenu} id="menuOpener">
                    <img src={getMainAggregated(user, 100)} alt="User thumbnail"/>
                    <span className={classes.name}>{user.name}</span>
                    <span className="tg-icon icon-tick-down"></span>
                </button>

                {isMenuOpened ? <DesktopMenu user={user} closeMenu={closeMenu} /> : null}
            </div>
        )
    }

    let headerClasses = (headerAlwaysVisible) ? [classes.Header, classes.AlwaysVisible] : [classes.Header];

    return (
        <Fragment>
            <div className={headerClasses.join(' ')}>
                <div className={classes.Wrapper}>
                    <div className={classes.MergedLogo}>
                        <NavLink to={aggGalleryUrl(user)} className={classes.LinkLogo} style={(publicDevMode() ? {opacity: '0.04'} : {})}>
                            {isTravelGirls
                                ? <img src={tgLogo} alt="Travelgirls"/>
                                : <img src={tfLogo} alt="Travelfriend" className={classes.TfLogo}/>
                            }
                        </NavLink>
                        <BackToState />
                    </div>
                    {userArea}
                </div>
            </div>
        </Fragment>
    );
}

export default Header;
