import {useDispatch} from "react-redux";
import aggPhoto from "../../../../../helpers/Photos";
import {modalActions} from "../../../../../store/modal";
import Classes from "./User.module.scss";

const User = ({user}) => {
    const dispatch = useDispatch();
    return (
        <div className={[Classes.cFlexItemSquare, Classes.UserSquare].join(' ')} onClick={() => dispatch(modalActions.open({name: 'login'}))}>
            <img src={aggPhoto(user.photo, 260)} className={Classes.UserImage} alt={'Photo of ' + user.name} />
            <div className={[Classes.UserImageBackground].join(' ')} />
            <div className={Classes.Info}>
                {user.name}
                <span className={Classes.Age}>{user.age}</span>
                {user.isOnline && (
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none" className={Classes.Online}>
                    <circle cx="5" cy="5" r="5" fill="#3DA80B"/>
                </svg>)}
            </div>
        </div>
    );
}

export default User;
