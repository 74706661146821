import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";


import {DOMAIN_NAME, metaDescription, titles} from "../config/Titles";


const Meta = () => {
    const {pathname} = useLocation();
    const [title, setTitle] = useState(DOMAIN_NAME);
    const [desc, setDesc] = useState('');

    useEffect(() => {
        setTitle((titles[pathname] !== undefined) ? titles[pathname] : DOMAIN_NAME);
        setDesc((metaDescription[pathname] !== undefined) ? metaDescription[pathname] : '');
    }, [pathname]);

    return (
        <Helmet>
            <title>{title}</title>
            {desc && <meta name="description" content={desc} />}
        </Helmet>
    );
}

export default Meta;
