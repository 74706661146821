import classes from './IosSwitch.module.scss';
import {useState} from "react";

const IosSwitch = (props) => {
    const [isSelected, toggleSelect] = useState(props.isSelected);

    let classList = [classes.Switch];
    if (isSelected) {
        classList.push(classes.SwitchSelected);
    }

    if (props.isDisabled) {
        classList.push(classes.SwitchDisabled);
    }

    const onClick = () => {
        if (props.isDisabled) {
            return;
        }
        const value = !isSelected;
        toggleSelect(value);

        if (typeof props.onSwitch === "function") {
            props.onSwitch(value);
        }
    }

    return (
        <>
            {props.children && <span onClick={onClick} className={(props.isDisabled) ? classes.Disabled : ''}>{props.children}</span>}
            <button className={classList.join(' ')} onClick={onClick}>
                <div className={classes.Bubble}></div>
            </button>
        </>
    );
}

export default IosSwitch;
