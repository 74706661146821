import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from '../../../config/Axios';

import {aggGalleryUrl} from "../../../helpers/UserUrl";
import LandingGayMobile from './LandingGayMobile';
import LandingGayWeb from './LandingGayWeb';

const LandingGay = () => {
    const nav = useNavigate();
    const user = useSelector(state => state.auth.user);
    const isMobile = useSelector(state => state.auth.isMobile);
    const [list, setList] = useState(null);

    const fetchGirls = () => {
        axios.get('landings/dubaiGirls').then((r) => {
            setList(r.data.users);
        });
    };

    // Redirect logged user to gallery
    useEffect(() => {
        if (user) {
            nav(aggGalleryUrl(user));
        }
    }, [user, nav]);

    useEffect(() => {
        fetchGirls();
    }, []);

    if (isMobile) {
        return (<LandingGayMobile girlsList={list} />);
    } else {
        return (<LandingGayWeb girlsList={list} />);
    }
}

export default LandingGay;
