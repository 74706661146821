import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import axios from '../../config/Axios';
import classes from './NewMembers.module.scss';
import aggPhoto from "../../helpers/Photos";
import {modalActions} from "../../store/modal";
const NEWLYREGISTERED = 'newlyRegisteredTab';

const NewMembers = () => {
    const dispatch = useDispatch();
    const nav = useNavigate();
    const user = useSelector(state => state.auth.user);
    const [list, updateList] = useState([]);
    const filter = (user ? user.galleryFilter : null);

    const getList = () => {
        const now = new Date();
        let cachedList = localStorage.getItem(NEWLYREGISTERED);

        if (cachedList) {
            const data = JSON.parse(cachedList);
            if (
                data.expiry > now.getTime() &&
                JSON.stringify(data.galleryFilter) === JSON.stringify(filter)) {
                updateList(data.userList);
                return;
            }
        }

        const searchPrefix = (filter && filter.sexList.includes('male')) ? 'men' : 'girls';

        axios.get('gallery/sidebar_newly_registered/'+searchPrefix).then(r => {
            let userList = r.data.map(item => item.photo);
            updateList(userList);
            localStorage.setItem(NEWLYREGISTERED, JSON.stringify({
                expiry: now.getTime() + 300000,
                galleryFilter: filter,
                userList: userList
            }));
        });
    }

    useEffect(getList, [filter]);

    const goToGallery = () => {
        if (!user) {
            dispatch(modalActions.open({name: 'login'}));
            return;
        }

        nav('/members/girls?sortBy=r');
    }

    return (
        <div className={classes.Container}>
            <div className={classes.Title}>New members</div>
            <button onClick={goToGallery} className={classes.Button}>
                <div className={classes.List}>
                    {list.map((url, i) => <img key={i} src={aggPhoto(url, 100)} alt={''} />)}
                    <div className={classes.GoButton}>
                        <i className="tg-icon icon-tick-right"></i>
                    </div>
                </div>
            </button>
        </div>
    );
}

export default NewMembers;
