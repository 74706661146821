import {io} from "socket.io-client";


import {rtmDomain} from "../config/Config";


export const socket = io(rtmDomain, {autoConnect: false});


export const socketDisconnect = () => {
    if (socket && socket.connected) {
        socket.removeAllListeners();
        socket.disconnect();
    }
}

export const socketListen = (data, callback) => {
    if (socket && socket.connected && data) {
         socket.emit('startListening', data);
         if (callback) {
             socket.on(data.eventType, callback);
         }
    }
}

export const socketStopListen = (data, callback) => {
    if (socket && socket.connected && data) {
        socket.emit('stopListening', data);
        if (callback) {
            socket.off(data.eventType, callback);
        }
    }
}

export const emitTypingEvent = (userId, converserId) => {
    if (socket && socket.connected) {
        socket.emit('chatNotifications', {event: 'typing', converserId: converserId, typerId: userId});
    }
}
export const emitSeenEvent  = (userId, converserId) => {
    if (socket && socket.connected) {
        socket.emit('chatNotifications', {event: 'seen', converserId: converserId});
    }
}
