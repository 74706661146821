export const getMobileOSData = () => {
    const ua = navigator.userAgent;
    let os = "Other";
    let version = 0;
    if (/android/i.test(ua)) {
      os = "Android";
    }
    else if (/iPad|iPhone|iPod/.test(ua)){
      os = "iOS";
      version = checkIosVersion(ua);
    }

    return {'os': os, 'version': version};
}

export const isStandAloneVersion = () => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
        return true
    }
    return false;
}

const checkIosVersion = function (agent) {
    var v = (navigator.userAgent).match(/OS (\d+)_(\d+)_?(\d+)?/);
    return (parseInt(v[1], 10) + '.' + parseInt(v[2], 10));
}