import {useDispatch} from "react-redux";
import {NavLink} from "react-router-dom";
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import {modalActions} from "../../../store/modal";
import classes from './LandingMobile.module.scss';

import Girls from './Girls';
import FacebookLogin from "../../../components/UI/FacebookLogin/FacebookLogin";

import tgLogo from '../../../assets/travelgirls-logo-coloured.svg';
import tfLogo from '../../../assets/logo-travelfriend-colored.svg';
import fbIcon from '../../../assets/landing/fb.png';
import igIcon from '../../../assets/landing/ig.png';
import twiterIcon from '../../../assets/landing/twiter.png'
import landingImg from '../../../assets/landing/landing-background.png';
import landingImgTf from '../../../assets/landing/landing-background-tf.png';
import landingWebp from '../../../assets/landing/landing-background.webp';
import landingWebpTf from '../../../assets/landing/landing-background-tf.webp';
import videoImg from '../../../assets/landing/olia-youtube.jpg';
import GoogleLoginButton from "../../../components/GoogleLoginButton";
import GooglePlayButton from "../../../components/common/GooglePlayButton";
import {flavor, isTravelGirls} from "../../../config/Flavor";
import videoImgTf from "../../../assets/travelfriend-youtube.png";


const LandingMobile = () => {
    const dispatch = useDispatch();

    return (
        <div className={classes.Landing}>
            <div className={classes.MobileHeader}>
                <div className={classes.HeaderLogo}>
                    {isTravelGirls?
                        <img src={tgLogo} alt="Travelgirls" />:
                        <img src={tfLogo} alt="Travelfriend" />
                    }
                </div>
                <h1 className={classes.HeaderTitle} dangerouslySetInnerHTML={{__html: flavor.title}}></h1>
                {!isTravelGirls && <h4>Travelfriend connects thousands of solo travelers across the world.</h4>}
            </div>
            <div className={classes.MainBackgroundAndSlogan}>
                <picture>
                    <source srcSet={isTravelGirls?landingWebp:landingWebpTf} type="image/webp" />
                    <img src={isTravelGirls?landingImg:landingImgTf} className={classes.LandingImg} alt={'Main background'} />
                </picture>

                <div className={classes.Login}>
                    <GoogleLoginButton />
                    <FacebookLogin />
                    <div className={classes.Or}>or</div>
                    <div className={classes.AuthActions}>
                        <button onClick={() => dispatch(modalActions.open({name: 'registration'}))} className={classes.AuthButton}>Register</button>
                        <span className={classes.Spacer}></span>
                        <button onClick={() => dispatch(modalActions.open({name: 'login', data: {openLogin: true} }))} className={[classes.AuthButton, classes.AuthButtonGrey].join(' ')}>Log in</button>
                    </div>
                </div>
                <h2 className={[classes.MobileOnly, classes.Padding].join(' ')}>Why You Should Join {flavor.name}</h2>
                <p className={[classes.SimpleText, classes.MobileOnly, classes.Padding].join(' ')}>Find friends from all around the world.<br />
                Get an opportunity to visit your dream country.<br />
                Guide people in your own town.<br />
                Meet your soulmate and share your trips together.</p>

                <p className={[classes.SubTitle, classes.MobileOnly, classes.Padding].join(' ')}><b>So, what are you waiting for?
                Join now and dive into your next adventure!</b></p>

                <div className={[classes.MobileOnly, classes.Center, classes.VideoContainer].join(' ')}>
                    <button onClick={() => dispatch(modalActions.open({name: 'tgVideo'}))}>
                        <img src={isTravelGirls?videoImg:videoImgTf} className={classes.videoPlayImg} alt={'Play Video'} />
                        <div className={classes.VideoPlayBtn}></div>
                    </button>
                </div>

                <h3 className={[classes.MobileOnly, classes.Padding].join(' ')}>About {flavor.name}</h3>
                <p className={[classes.SubTitle, classes.MobileOnly, classes.Padding].join(' ')}><b>{flavor.name} started in 2010 and quickly grew
                    to over three million members today.</b></p>

                <p className={[classes.SimpleText, classes.MobileOnly, classes.Padding].join(' ')}>You are backed by a professional and proactive
                customer service.
                    {flavor.name} is a secure and lively travel-dating
                venue for you to enjoy.</p>
            </div>

            <LazyLoadComponent >
                <Girls />
            </LazyLoadComponent>

            <div className={classes.MobileFooter}>
                <div className={`${classes.FooterInner} ${!isTravelGirls ? classes.emptyBackground : ''}`}>

                    <div className={classes.Logos}>
                        <GooglePlayButton className={classes.GoogleBadge} />
                    </div>
                    <div className={classes.Secure}>
                        <i className={['tg-icon', 'icon-lock-2', classes.Lock].join(' ')}></i>
                        <span className={classes.Text}>100% secure</span>
                        <i className={['tg-icon', 'icon-visa', classes.CreditCards].join(' ')}></i>
                        <i className={['tg-icon', 'icon-master-card', classes.CreditCards].join(' ')}></i>
                    </div>
                    <div className={classes.Social}>
                        <img src={fbIcon} alt="Social facebook" width="12" height="25" />
                        <img src={twiterIcon} alt="Social twiter" width="30" height="25" />
                        <img src={igIcon} alt="Social instagram" width="25" height="25" />
                    </div>
                    <div className={classes.Links}>
                        <NavLink to="/about-us">About {flavor.name}</NavLink>
                        <NavLink to="/faq">Need help?.</NavLink>
                        <NavLink to="/travel-tips">Travel tips</NavLink>
                    </div>
                    <div className={classes.Links}>
                        <NavLink to="/sugar-daddy-dating">Popular cities</NavLink>
                        <NavLink to="/support">Contact us</NavLink>
                    </div>

                    <div className={classes.AllRights}>
                        All rights are reserved. TM Solutions Limited 2010 - {new Date().getFullYear()} © {flavor.domainName}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingMobile;
