import {useEffect} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {aggGalleryUrl} from "../../../helpers/UserUrl";
import LandingMobile from './LandingMobile';
import LandingWeb from './LandingWeb';
import {modalActions} from "../../../store/modal";

const Landing = () => {
    const nav = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const user = useSelector(state => state.auth.user);
    const isMobile = useSelector(state => state.auth.isMobile);
    const isExpired = searchParams.get('session') === 'expired';

    // Redirect logged user to gallery
    useEffect(() => {
        if (user) {
            nav(aggGalleryUrl(user));
        }
    }, [user, nav]);

    useEffect(() => {
        if (!isExpired || user) {
            return;
        }

        dispatch(modalActions.open({
            name: 'notificationMessage',
            data: {
                title: 'Session expired',
                text: 'Your session has expired. Please login again.'
            }
        }));
    }, [user, isExpired, dispatch]);

    if (isMobile) {
        return (<LandingMobile />);
    } else {
        return (<LandingWeb />);
    }
}

export default Landing;
