import React from 'react';
import ImgPeople from "../styles/media/v3/people.png";
import ImgLake from "../styles/media/v3/lake.png";
import ImgDinner from "../styles/media/v3/dinner.png";
import ImgYacht from "../styles/media/v4/yacht.png";
import ImgGirls from "../styles/media/v4/girls.png";
import ImgGirlYacht from "../styles/media/v4/girlYacht.png";
import Classes from "./ImageSection.module.scss";

const ImageSection = ({version}) => {

    const Img1 = version === 3 ? ImgPeople : ImgYacht;
    const Img2 = version === 3 ? ImgLake : ImgGirls;
    const Img3 = version === 3 ? ImgDinner : ImgGirlYacht;

    return (
        <div className={Classes.Root}>
            <div className={Classes.ImageItem}><img src={Img1} alt='' loading='lazy'/></div>
            <div className={Classes.ImageItem}><img src={Img2} alt='' loading='lazy'/></div>
            <div className={Classes.ImageItem}><img src={Img3} alt='' loading='lazy'/></div>
        </div>
    );
};

export default ImageSection;
