import {Fragment} from "react";

import classes from './GirlsList.module.scss';
import Loader from '../../../../components/UI/Loader/Loader';
import aggPhoto from "../../../../helpers/Photos";

const GirlsRounded = ({list, onGirlClick}) => {
    return (
        <div className={classes.GirlsWrapper}>
            {list === null
                ? <Loader/>
                : (
                    <Fragment>
                        
                        <div className={classes.GirlsList}>
                            {list.map((item, id) => {
                                return (
                                    <div onClick={onGirlClick} key={id} className={classes.User}>
                                        <img src={aggPhoto(item.photo, 200)} alt={''}/>
                                        <div className={classes.AreaName}>
                                            <span className={classes.Name}>{item.name}</span>
                                            <span className={classes.Age}>{item.age}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Fragment>
                )
            }
        </div>
    );
}

export default GirlsRounded;
