import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from "react-redux";

import NewMembers from '../../../components/NewMembers/NewMembers';
import { aggGalleryUrl } from "../../../helpers/UserUrl";

import classes from './Sidebar.module.scss';
import {isTravelGirls} from "../../../config/Flavor";

const Sidebar = (props) => {
    const user = useSelector(state => state.auth.user);
    const counters = useSelector(state => state.counters);

    let userMenu = null;
    let urlMembers = aggGalleryUrl(user);

    if (user) {
        userMenu = (
            <Fragment>
                <NavLink to="/conversations" className={({isActive}) => isActive ? classes.active : undefined}>
                    <i className="tg-icon icon-conversations"></i>
                    Messages
                    {counters.unreadConversations > 0
                        ? <span className={classes.Counter}>{counters.unreadConversations}</span>
                        : null
                    }
                </NavLink>
                <NavLink to="/favorites" className={({isActive}) => isActive ? classes.active : undefined}>
                    <i className="tg-icon icon-star-full"></i>
                    Favorites
                </NavLink>
                <NavLink to="/visitors" className={({isActive}) => isActive ? classes.active : undefined}>
                    <i className="tg-icon icon-eye"></i>
                    Profile views
                    {counters.unreadViews > 0
                        ? <span className={classes.Counter}>{counters.unreadViews}</span>
                        : null
                    }
                </NavLink>
                {isTravelGirls &&
                    <NavLink to="/photo-requests" className={({isActive}) => isActive ? classes.active : undefined}>
                    <i className="tg-icon icon-private-photos"></i>
                    Photo request
                    {counters.photoRequests > 0
                        ? <span className={classes.Counter}>{counters.photoRequests}</span>
                        : null
                    }
                </NavLink>}
                <NavLink to="/trips" className={({isActive}) => isActive ? classes.active : undefined}>
                    <i className="tg-icon icon-trips-map"></i>
                    Trips
                    {counters.unreadTrips > 0
                        ? <span className={classes.Counter}>{counters.unreadTrips}</span>
                        : null
                    }
                </NavLink>
            </Fragment>
        );
    }

    return (
        <div className={classes.Sidebar}>
            <div className={classes.Menu}>
                <NavLink to={urlMembers} className={({isActive}) => isActive ? classes.active : undefined}>
                    <i className="tg-icon icon-group-profile"></i>
                    Members
                </NavLink>
                {userMenu}
                <NavLink to="/support" className={({isActive}) => isActive ? classes.active : undefined}>
                    <i className="tg-icon icon-support"></i>
                    Support
                </NavLink>
            </div>

            {props.newMembersInSidebar ? <NewMembers /> : null}
        </div>
    )
}

export default Sidebar;
