import React from 'react';
import Button from "./Button/Button";
import Classes from './Section.module.scss'

const Section = ({version, isFirst = false}) => {
    return (
        <div className={Classes.Root}>
            {version === 3 &&
                <p className={Classes.Title}>
                    {isFirst ?
                        `Travelfriend is the biggest online community of people who are ready to have fun`
                        : `Travelfriend's main mission is to connect people so they can elevate their traveling experiences`}</p>}
            {version === 4 &&
                <p className={Classes.Title}>{isFirst ?
                    `Travelfriend is the biggest online community of people who crave fun`
                    : `Travelfriend's mission is to make sure your next trip has everything you've dreamt of`}</p>}
            <div>
                {version === 3 && <p className={Classes.SubTitle}>{
                    isFirst ? `With more than 2 million registered users you're just a few clicks away
                    from finding a company for
                    your upcoming trip` : `Food, laugh, champagne, summer breeze & nearly everything is better when you have someone to share it with`}</p>}
                {version === 4 &&
                    <p className={Classes.SubTitle}>{
                        isFirst ? `With over 2 million users, finding what you're looking for is only a few clicks away`
                            : `Especially when it comes to finding a company to enjoy the sun, food, drinks, and many more with`}
                    </p>}

                <Button additionalStyle={Classes.Button}>Find a match</Button>
            </div>
        </div>
    );
};


export default Section;
