import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    list: [],
}

const groupMessageSlice = createSlice({
    name: 'groupMessage',
    initialState: initialState,
    reducers: {
        set(state, action) {
            state.list = action.payload.list;
        },
        add(state, action) {
            state.list = state.list.concat(action.payload.message);
        },
        delete(state, action) {
            state.list = state.list.filter(l => l.ID !== action.payload.messageId);
        }
    }
});

export const groupMessageActions = groupMessageSlice.actions;
export default groupMessageSlice.reducer;
