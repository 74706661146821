import {useEffect, useState} from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

import classes from '../Login/Login.module.scss';
import classesInternal from './ForgotPassword.module.scss';
import axios from "../../config/Axios";
import Loader from "../UI/Loader/Loader";
import {validateEmail} from "../../helpers/Validators";


const ForgotPassword = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        const removeError = ()  => {
            if (!error) {
                return;
            }

            if (validateEmail(email)) {
                setError(false);
            }
        };
        removeError();
    }, [error, email, success])

    const showSuccessMessage = () => {
        setSuccess(true);
        setIsLoading(false);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (error || isLoading) {
            return;
        }

        if (!validateEmail(email)) {
            setError(true);
            return;
        }

        // Send password reminder
        setIsLoading(true);

        initPost(email);
    }

    const initPost = async (email) => {
        const data = {
            email: email,
            googleRecaptchaToken: executeRecaptcha ? await executeRecaptcha('tg_react_login') : null
        };
        axios.post('password/remind', data).then(showSuccessMessage).catch(showSuccessMessage);
    }

    return (
        <div className={[classes.AreaJoinUs, classesInternal.PasswordArea].join(' ')}>
            <div className={classes.OptionsScreen}>
                <div className={classes.Title}>Forgot password?</div>
                <form className={classes.LoginForm} onSubmit={onSubmit}>
                    <div className={classes.RegistrationText}>
                        Don't worry. Enter your email address, that was used to register on this website and we will
                        send you further instructions how to get a new password.
                    </div>

                    <div className={[classes.InputLabel].join(' ')}>
                        E-mail
                        {error ? <div className={classes.InputError}>Incorrect email address!</div> : null}
                    </div>
                    <input
                        className={error ? classes.Errored : null}
                        autoComplete={'off'}
                        type="email"
                        name="email"
                        placeholder="Email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    {success ? <div className={classesInternal.SuccessMessage}>A reminder was sent to given email.</div> : null}

                    <div className={classesInternal.Spacer}></div>
                    <button type="submit" className={[classes.AuthButton, classes.LoginAction].join(' ')} disabled={success || isLoading}>
                        {isLoading ? <Loader white={true} /> : 'Get me a new password'}
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ForgotPassword;
