import {useSelector} from "react-redux";

const NotificationMessage = () => {
    const {title, text} = useSelector(s => s.modal.data);

    return (
        <div style={{textAlign: 'center', width: '100%'}}>
            <h2>{title}</h2>
            <p>{text}</p>
        </div>
    );
}


export default NotificationMessage;
