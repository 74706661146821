import {createSlice} from "@reduxjs/toolkit";
import axios from "../config/Axios";

const initialState = {
    initAt: null,
    historyId: null,
    isLoading: true,
    users: [],
    hasMore: false,
    params: {
        from: 0,
        limit: 20,
        time: null
    }
}

const visitorsSlice = createSlice({
    name: 'visitors',
    initialState: initialState,
    reducers: {
        fetch(state) {
            state.isLoading = true;
        },
        set(state, action) {
            let userList = [...state.users, ...action.payload.users];

            state.isLoading = false;
            state.users = userList;
            state.hasMore = action.payload.hasMore;
            state.params.from = userList.length;
            state.params.time = (userList.length > 0) ? userList[userList.length - 1]._additional.viewedAt : null;
        },
        removeOne(state, action) {
            state.users = state.users.filter(u => u.id !== action.payload.userId)
        },
        reset (state, action) {
            return {
                ...initialState,
                historyId: action.payload.historyId,
                initAt: new Date().getTime()
            }
        }
    }
});

export const fetchNew = (userId, historyId, unreadViews) => {
    return async (dispatch, getState) => {
        dispatch(visitorsActions.reset({historyId: historyId}));

        let params = {...getState().visitors.params};
        if (unreadViews) {
            params['unsetNew'] = 1;
        }

        dispatch(getVisitors(userId, params, unreadViews));
    }
}

export const getVisitors = (userId, params, unreadViews) => {
    return async (dispatch) => {
        dispatch(visitorsActions.fetch());
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        const list = await axios.get('/views/'+userId+'?'+queryString);


        let payload = {
            users: setUsers(list.data.views, unreadViews),
            hasMore: !!list.data.hasMore
        };

        dispatch(visitorsActions.set(payload));
    }
}

export const removeView = (userId, deleteForever = false) => {
    return (dispatch, getState) => {
        const loggedId = getState().auth.user.id;
        axios.post('/views/'+loggedId+'/'+userId+'/delete', {deleteForever});

        dispatch(visitorsActions.removeOne({userId: userId}));
    }
}

const setUsers = (list, unreadViews) => {
    return list.map((item, i) => {
        return {
            ...item.user,
            _additional: {
                isNew: typeof unreadViews !== "undefined" && unreadViews > i,
                viewCount: item.viewCount,
                viewedAt: item.viewedAt
            }
        }
    });
}

export const visitorsActions = visitorsSlice.actions;
export default visitorsSlice.reducer;
