export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const validatePassword = (pswd) => {
    return (pswd.length >= 4);
}

export const validateName = (name) => {
    if (name.length < 3) {
        return 'Min 3 characters.';
    }

    if (/[^a-zA-Z]/.test(name)) {
        return 'Letters only';
    }

    return null;
}
