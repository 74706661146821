import Logo from "../../styles/media/logoDark.png";
import Classes from "./HeaderLight.module.scss";
import Button from "../../Components/Button/Button";

const HeaderLight = () => (
    <>

        <div className={Classes.Container}>
            <div className={Classes.Wrapper}>
                <img src={Logo} className={Classes.Logo} alt={"Travelfriend Logo"} />
                <Button additionalStyle={Classes.ButtonHeaderLight} fromHeader>Join the club</Button>
            </div>
        </div>
        <div className={Classes.HeaderSpacer} />
    </>
);

export default HeaderLight;
