import React from 'react';
import Classes from "./JoinSection.module.scss";
import Button from "./Button/Button";

const JoinSection = ({version}) => {
    return (
        <div className={Classes.Root}>
            <h2 className={Classes.Title}>
                {version === 3 ? 'Simply better when shared.' : 'Become part of the community'}
            </h2>
            <h2 className={Classes.SubTitle}>
                {version === 3 ? 'The easiest way to find a company for your upcoming trip' : 'Never travel alone'}
            </h2>
            <Button additionalStyle={Classes.Button}>Join Travelfriend</Button>
        </div>
    );
};

export default JoinSection;
