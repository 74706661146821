import {useDispatch, useSelector} from "react-redux";
import {NavLink} from 'react-router-dom';

import classes from './MenuMobile.module.scss';
import {aggGalleryUrl} from "../../../helpers/UserUrl";
import {modalActions} from "../../../store/modal";
import {routes} from "../../../config/Routes";
import {isTravelGirls} from "../../../config/Flavor";

const MenuMobile = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const counters = useSelector(state => state.counters);
    const hasNotifications = (user && user.additional.notifications.length > 0);
    const unreadNewActivity = (user && (counters.unreadConversations > 0 || counters.unreadViews > 0 || counters.photoRequests > 0 ));

    const checkForAuth = (e) => {
        if (user) {
            return;
        }

        e.preventDefault();
        dispatch(modalActions.open({name: 'login'}));
    }

    return (
        <div className={classes.AppMenu}>
            <div className={classes.NavItems}>
                <NavLink to={aggGalleryUrl(user)} className={({isActive}) => isActive ? classes.Selected : undefined}>
                    {isTravelGirls ? <i className="tg-icon icon-tg-logo" /> : <i className="tg-icon icon-group-profile" />}
                    <span className={classes.Text}>Members</span>
                </NavLink>

                <NavLink onClick={checkForAuth} to={routes.favorites.path} className={({isActive}) => isActive ? classes.Selected : undefined}>
                    <i className="tg-icon icon-star-full"></i>
                    <span className={classes.Text}>Favorites</span>
                </NavLink>

                <NavLink to={routes.trips.path} className={({isActive}) => isActive ? classes.Selected : undefined}>
                    <i className="tg-icon icon-trips-map"></i>
                    <span className={classes.Text}>Trips</span>
                </NavLink>

                <NavLink onClick={checkForAuth} to={routes.conversations.path} className={({isActive}) => isActive ? classes.Selected : undefined}>
                    <div style={{position: 'relative'}}>
                        <i className="tg-icon icon-chat"></i>
                        {unreadNewActivity && <div className={`${classes.Indicator} ${classes.Pulse}`}></div>}
                    </div>
                    <span className={classes.Text}>Activity</span>
                </NavLink>

                <NavLink onClick={checkForAuth} to={routes.myProfile.path} className={({isActive}) => isActive ? classes.Selected : undefined}>
                    <div style={{position: 'relative'}}>
                        <i className="tg-icon icon-profile-background"></i>
                        {hasNotifications && <div className={classes.Indicator}></div>}
                    </div>
                    <span className={classes.Text}>Profile</span>
                </NavLink>
            </div>
        </div>
    );
}

export default MenuMobile;
