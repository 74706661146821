import {useEffect, useState} from 'react';
import axios from 'axios';
import classes from './Autocomplete.module.scss';
import {googleAutocompleteProxyDomain} from "../../../config/Config";

const config = {
    minChars: 2,
    domain: googleAutocompleteProxyDomain
}

const Autocomplete = (props) => {
    const [query, setQuery] = useState('');
    const [placeholder, setPlaceholder] = useState('Location');
    const [predictions, setPredictions] = useState([]);
    //const [location, setLocation] = useState(null);
    const [selectedPrediction, setSelectedPrediction] = useState(null);

    useEffect(() => {
        if (props.query) {
            setQuery(props.query);
            setSelectedPrediction({}); // Just to show reset button in autocomplete
        }

        if(!props.query){
            setQuery('');
            setSelectedPrediction(null);
        }

        if (props.placeholder) {
            setPlaceholder(props.placeholder);
        }
    }, [props.query]); // eslint-disable-line react-hooks/exhaustive-deps

    const onInputChange = event => {
        const newVal = event.target.value;
        setQuery(newVal);

        if (newVal.length >= config.minChars) {
            axios.get((config.domain+'/predictions?s='+newVal))
                .then((result) => {
                    setPredictions(result.data);
                })
        }

        if (newVal.length === 0) {
            setPredictions([]);

            // reset trip location
            if (props.onInputChange) {
                props.onInputChange(newVal);
            }
        }
    };

    const onSelectLocation = async prediction => {
        if (prediction.place_id) {
            setQuery(prediction.description);
            setPredictions([]);
            setSelectedPrediction(prediction);

            await axios.get((config.domain+'/details?placeId='+prediction.place_id))
                .then((result) => {
                    //setLocation(result.data);
                    props.onLocationSelect(parse(result.data));

                    if (props.resetAfterSelect) {
                        reset();
                    }
                });
        }
    };

    const reset = () => {
        setQuery('');
        setPredictions([]);
        //setLocation(null);
        setSelectedPrediction(null);
    }

    const resetAutocomplete = () => {
        reset();
        props.onLocationSelect(null);
    }

    const parse = o => {
        if (!o) {
            return null;
        }

        let data = {
            'locationId': o.place_id,
            'locationName': o.formatted_address,
            'locationNameShort': o.name,
            'locationType': (typeof o.types == 'object') ? o.types[0] : o.types,
            'coords': {
                'lon': o.geometry.location.lng,
                'lat': o.geometry.location.lat
            }
        };
        o.address_components.forEach((item) => {
            if (typeof item.types === 'object') {
                if (item.types.indexOf('country') >= 0) {
                    data.locationCountryCode = item.short_name;
                    data.locationCountryName = item.long_name;
                }
            }
        });

        return data;
    }

    let classList = [classes.Element];
    if (props.className) {
        classList.push(props.className);
    }

    return (
        <div className={classList.join(' ')}>
            {selectedPrediction ? (
                <button onClick={resetAutocomplete} className={classes.CloseIcon}>
                    <i className="tg-icon icon-close-big"></i>
                </button>
            ) : null}

            <div className={classes.Inner}>
                <div className={classes.LocationIcon}>
                    <i className="tg-icon icon-search"></i>
                </div>
                <input type="text" placeholder={placeholder} value={query} onChange={onInputChange} autoComplete="off" />
            </div>

            <Results predictions={predictions} onSelect={onSelectLocation} />
        </div>
    );
}

const Results = (props) => {
    const predictions = props.predictions;

    if (predictions.length < 1) {
        return null;
    }

    return (
        <div className={classes.Results}>
            {predictions.map((p) => (
                <div key={p.description}>
                    <div className={classes.Item} onClick={() => props.onSelect(p)}>{p.description}</div>
                </div>
            ))}
        </div>
    );
}

export default Autocomplete;
