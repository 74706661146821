import {useEffect, useState} from "react";
import { useSelector } from "react-redux";

import { getCurrencySign } from "../../helpers/Text";
import Loader from "../UI/Loader/Loader";
import classes from '../../containers/Pages/Membership/BusinessMembership/BusinessPlan.module.scss';
import ImgBenefit2  from "../../containers/Pages/Membership/BusinessMembership/assets/membership-icon-2.png"
import ImgBenefit3  from "../../containers/Pages/Membership/BusinessMembership/assets/membership-icon-3.png"
import ImgBenefit4  from "../../containers/Pages/Membership/BusinessMembership/assets/membership-icon-4.png"
import ImgBenefit5  from "../../containers/Pages/Membership/BusinessMembership/assets/membership-icon-5.png"
import ImgBenefit6  from "../../containers/Pages/Membership/BusinessMembership/assets/membership-icon-6.png"
import axios from "../../config/Axios";
import SuccessIcon from "../UI/SuccessIcon";

const VipUpgrade = () => {
    const [success, setSuccess] = useState(false);

    const user = useSelector(state => state.auth.user);
    const currency = user.additional.prices.currency
    const classList = [classes.Plan, classes.Diamond, classes.Selected];

    // Reload on success upgrade
    useEffect(() => {
        if (success) setTimeout(() => window.location.reload(), 2000);
    }, [success]);

    // Show success message
    if (success) {
        return (
            <div className={classes.SuccessMessage}>
                <SuccessIcon />
                <span>Membership upgraded successfuly!</span>
            </div>
        );
    }

    return (
        <div className={[classes.Area, classes.AlwaysVisible].join(' ')}>
            <div className={classList.join(' ')}>
                <div className={`${classes.PlanHeader} ${classes.Diamond}`}>
                    <div className={classes.Header}>
                        <div><span className={classes.Gold}>VIP</span> Traveller</div>
                    </div>
                    <div className={classes.DayPrice}><span className={classes.Big}>{getCurrencySign(currency)}256</span> for month</div>
                    <div className={classes.MonthPrice}>{getCurrencySign(currency)}8.5 per day!</div>
                </div>

                <UpgradeButton user={user} onSucess={() => setSuccess(true)} />
                <Benefits />
            </div>
        </div>
    );
};

const UpgradeButton = ({user, onSucess}) => {
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const onClick = (e) => {
        e.preventDefault();
        if (isLoading) {
            return;
        }

        setError('');
        setLoading(true);

        axios.post(`user_payments/${user.id}/upgrade_to_vip`)
            .then(r => onSucess())
            .catch(r => setError(r.response.data.error))
            .finally(() => setLoading(false));
    }

    return (
        <>
            {error.length > 0 && <div className={classes.ErrorMessage}>{error}</div>}
            <button onClick={onClick} className={`ButtonRounded ${classes.DiamondButton}`} style={{minWidth: '190px'}}>
                {!isLoading ? <b>Upgrade to VIP</b> : <Loader white/>}
            </button>
        </>
    );
}

const list = [
    {title: 'TOP status', text: 'You get highest reply rate', img: {src: ImgBenefit2, alt: 'benefits'}},
    {title: 'Highlighted in inbox', text: 'Get your messages read first', img: {src: ImgBenefit3, alt: 'benefits'}},
    {title: 'Verification photos', text: 'See how girls look in real life', img: {src: ImgBenefit4, alt: 'benefits'}},
    {title: 'Browse anonymous', text: 'Hide your profile from locations or people you choose', img: {src: ImgBenefit5, alt: 'benefits'}},
    {title: 'Priority', text: 'Get instant priority support', img: {src: ImgBenefit6, alt: 'benefits'}},
];

const BenefitItem = ({title, text, img}) => (
    <div className={classes.BenefitItem}>
        <div className={classes.Image}><img src={img.src} alt={img.alt} /></div>
        <div className={classes.Info}>
            <div className={classes.InfoTitle}>{title}</div>
            <div className={classes.Text}>{text}</div>
        </div>
    </div>
);

const Benefits = () => (
    <div className={classes.Benefits}>
        {list.map(i => <BenefitItem key={i.title} title={i.title} text={i.text} img={i.img} />)}
    </div>
);

export default VipUpgrade;
