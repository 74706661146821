import {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import ReactCountryFlag from "react-country-flag"

import axios from '../../../config/Axios';
import classes from './MemberList.module.scss';
import Loader from '../../../components/UI/Loader/Loader';
import {getCountryName} from '../../../helpers/Countries';
import aggPhoto from '../../../helpers/Photos';
import { modalActions } from "../../../store/modal";

const MenList = () => {
    const dispatch = useDispatch();
    const [list, setList] = useState(null);
    const [location, setLocation] = useState(null);
    const user = useSelector(s => s.auth.user);

    const fetchMembers = () => {
        axios.get('landings/maleList').then((r) => {
            setList(r.data.users);
            setLocation(r.data.location);
        });
    };

    useEffect(() => {
        if (!user) {
            fetchMembers();
        }

        const timeoutId = setTimeout(() => {
            if (!user) {
                dispatch(modalActions.open({ name: 'login', disabled: true }));
            }
        }, 3000);
        return () => clearTimeout(timeoutId); // Clear the timeout on component unmount or when `user` changes.
    }, [user, dispatch]);

    const countryCode = (location) ? location.countryCode : null;
    return (
        <div className={classes.Wrapper}>
            {list === null
                ? <Loader/>
                : (
                    <Fragment>
                        <div className={classes.Title}>
                            {countryCode
                                ? (
                                    <Fragment>
                                        <ReactCountryFlag countryCode={countryCode}/> Travelers who are now visiting {getCountryName(countryCode)}
                                    </Fragment>
                                )
                                : <Fragment>Travelers online</Fragment>
                            }
                        </div>
                        <div className={classes.List}>
                            {list.map((item, id) => {
                                return (
                                    <NavLink to={'/members/men'} key={id} className={classes.User}>
                                        <img src={aggPhoto(item.photo, 200)} alt={''}/>
                                        <div className={classes.AreaName}>
                                            <span className={classes.Name}>{item.name}</span>
                                            <span className={classes.Age}>{item.age}</span>
                                            <span className={classes.Online}><i
                                                className="tg-icon icon-online"></i></span>
                                        </div>
                                        <div
                                            className={classes.Country}>{getCountryName(item.location.countryCode)}</div>
                                    </NavLink>
                                )
                            })}
                        </div>
                    </Fragment>
                )
            }
        </div>
    );
}

export default MenList;
