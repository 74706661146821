import {useState} from "react";
import {useDispatch} from "react-redux";
import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";


import {modalActions} from "../../store/modal";
import {loginWithGoogle} from "../../store/auth";
import {googleClientId} from "../../config/Config";
import Classes from "./main.module.scss";
import Loader from "../UI/Loader/Loader";

const GoogleLoginButton = () => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const onSuccess = () => dispatch(modalActions.close());
    const onFail = () => {
        setLoading(false);
        alert('Login with google - failed.')
    }
    const initApiCall = (data) => {
        setLoading(true);
        dispatch(loginWithGoogle(data, onSuccess, onFail));
    }

    return (
        <GoogleOAuthProvider clientId={googleClientId}>
                <div className={Classes.Main}>
                    {isLoading && <div className={Classes.LoadingContainer}><Loader /></div>}
                    <GoogleLogin shape={"pill"} size={"large"} text={"signin_with"} onSuccess={(r) => initApiCall(r)} />
                </div>
        </GoogleOAuthProvider>
    );
}
export default GoogleLoginButton;
