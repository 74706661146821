import {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import Select from "../UI/Select/Select";
import Loader from "../UI/Loader/Loader";
import classes from './Age.module.scss';
import {updateUser} from "../../store/auth";
import {modalActions} from "../../store/modal";

import imgBackgroundMale from '../../assets/thumbs/male.svg';
import imgBackgroundFemale from '../../assets/thumbs/female.svg';
import questionMark from '../../assets/questionMark.png';

const getYears = (startYear) => {
    let currentYear = new Date().getFullYear() - 17;
    let list = [];
    startYear = currentYear - 99;
    while ( startYear <= currentYear ) {
        let currentVal = currentYear--;
        list.push({
            label: currentVal,
            value: currentVal
        });
    }
    return list;
}
const yearList = getYears();
const monthList = [
    {label: 'Jan', value: '01'},
    {label: 'Feb', value: '02'},
    {label: 'Mar', value: '03'},
    {label: 'Apr', value: '04'},
    {label: 'May', value: '05'},
    {label: 'Jun', value: '06'},
    {label: 'Jul', value: '07'},
    {label: 'Aug', value: '08'},
    {label: 'Sep', value: '09'},
    {label: 'Oct', value: '10'},
    {label: 'Nov', value: '11'},
    {label: 'Dec', value: '12'}
];
const dayList = [
    {label: '01', value: '01'},
    {label: '02', value: '02'},
    {label: '03', value: '03'},
    {label: '04', value: '04'},
    {label: '05', value: '05'},
    {label: '06', value: '06'},
    {label: '07', value: '07'},
    {label: '08', value: '08'},
    {label: '09', value: '09'},
    {label: '10', value: '10'},
    {label: '11', value: '11'},
    {label: '12', value: '12'},
    {label: '13', value: '13'},
    {label: '14', value: '14'},
    {label: '15', value: '15'},
    {label: '16', value: '16'},
    {label: '17', value: '17'},
    {label: '18', value: '18'},
    {label: '19', value: '19'},
    {label: '20', value: '20'},
    {label: '21', value: '21'},
    {label: '22', value: '22'},
    {label: '23', value: '23'},
    {label: '24', value: '25'},
    {label: '25', value: '25'},
    {label: '26', value: '26'},
    {label: '27', value: '27'},
    {label: '28', value: '28'},
    {label: '29', value: '29'},
    {label: '30', value: '30'},
    {label: '31', value: '31'}
];

const Age = (props) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const user = useSelector(state => state.auth.user);
    const backgroundImg = (user.sex == 'female') ? imgBackgroundFemale : imgBackgroundMale;

    const addAgeHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        let postData = {bday: (event.target.bdayYear.value+'-'+event.target.bdayMonth.value+'-'+event.target.bdayDay.value)};
        dispatch(updateUser(user.id, postData));
        dispatch(modalActions.close());
    }

    return (
        <div className={classes.Age}>
            <div className={classes.AgeBackground}>
                <img src={backgroundImg} alt="profile placeholder" />
                <img src={questionMark} className={classes.QuestionMark} alt="question mark" />
            </div>
            <div className={classes.ContentContainer}> 
                <div className={classes.Content}>
                    <h2>Missing info</h2>
                    <div className={classes.SimpleText}>Please update your birth date</div>
                    <form className={classes.AgeFormn} onSubmit={addAgeHandler}>
                        <div className={classes.InputContainer}>
                            <div className={classes.DateYear}>
                                <Select name={'bdayYear'} list={yearList} cssOverride={'SelectWhite'} />
                            </div>
                            <div className={classes.DateMonth}>
                                <Select name={'bdayMonth'} list={monthList} cssOverride={'SelectWhite'} />
                            </div>

                            <div className={classes.DateDay}>
                                <Select name={'bdayDay'} list={dayList} cssOverride={'SelectWhite'} />
                            </div>
                        </div>
                        <div className={classes.SaveArea}>
                            <button type="submit" className={classes.Save} >
                            {isLoading ? <Loader white={true} /> : 'Update Now'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Age;