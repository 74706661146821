import classes from './PhotoRequest.module.scss';
import thumbFemalePrivate from "../../assets/thumbs/female-locked.svg";
import thumbMalePrivate from "../../assets/thumbs/male-locked.svg";
import {useDispatch, useSelector} from "react-redux";
import {sendPrivatePhotoRequest} from "../../store/openedUser";

const PhotoRequest = () => {
    const dispatch = useDispatch();
    const requesterId = useSelector(state => state.auth.user.id);
    const {userId, isRequested, sex} = useSelector(state => state.modal.data);

    const onClick = () => {
        dispatch(sendPrivatePhotoRequest(requesterId, userId));
    }

    return (
        <div className={classes.Area}>
            <img className={classes.Image} src={sex === 'female' ? thumbFemalePrivate : thumbMalePrivate} alt=""/>
            <div className={classes.Title}>Private photo</div>
            <div className={classes.Text}>Do you want to request permission to see private photos?</div>
            {!isRequested
                ? <button onClick={onClick} className={['ButtonRounded', 'ButtonRoundedBig', classes.RequestButton].join(' ')}>Request permission</button>
                : <div className={classes.RequestSent}>Request sent</div>
            }
        </div>
    );
}

export default PhotoRequest;
