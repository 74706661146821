import {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import Autocomplete from "../UI/Autocomplete/Autocomplete";
import Loader from "../UI/Loader/Loader";
import classes from './Location.module.scss';
import {updateUser} from "../../store/auth";
import {modalActions} from "../../store/modal";

import imgBackground from '../../assets/location-background.png';
import {flavor} from "../../config/Flavor";

const Location = (props) => {
    const dispatch = useDispatch();
    const [location, setLocation] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const user = useSelector(state => state.auth.user);

    const addLocationHandler = (event) => {
        event.preventDefault();
        if (!location) return;
        setIsLoading(true);

        dispatch(updateUser(user.id, {location:location}));
        dispatch(modalActions.close());
    }

    return (
        <div className={classes.Location}>
            <div className={classes.LocationBackground}><img src={imgBackground} alt="location bacground" /></div>
            <h1>Location <i className="tg-icon icon-location"></i></h1>
            <div className={classes.ContentContainer}>
                <div className={classes.Content}>
                    <h2>{flavor.name} would like to know your location</h2>
                    <div className={classes.SimpleText}>To find {flavor.name} members close by, please add your location.</div>
                    <form className={classes.LocationFormn} onSubmit={addLocationHandler}>
                        <div className={classes.InputContainer}>
                            <Autocomplete onLocationSelect={setLocation} className={classes.AddLocationAutocomplete} />
                            <div className={classes.InputIcon}><i className="tg-icon icon-location"></i></div>
                        </div>
                        <div className={classes.SaveArea}>
                            <button type="submit" className={classes.Save} >
                            {isLoading ? <Loader white={true} /> : 'Save'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Location;
