import {createSlice} from "@reduxjs/toolkit";
import axios from "../config/Axios";
import {modalActions} from "./modal";

const initialState = {
    user: null
}

const openedUser = createSlice({
    name: 'openedUser',
    initialState: initialState,
    reducers: {
        set(state, action) {
            state.user = action.payload.user;
        },
        sendPhotoRequest (state) {
            state.user.photos.isPrivatePhotosRequested  = true;
        }
    }
});

export const sendPrivatePhotoRequest = (requesterId, userId) => {
    return (dispatch) => {
        dispatch(modalActions.close());
        dispatch(openedUserActions.sendPhotoRequest());
        axios.post('/user/'+requesterId+'/'+userId+'/send_private_photo_request');
    }
}

export const openedUserActions = openedUser.actions;
export default openedUser.reducer;
