export const getStoremMessageId = (id) => ('message_'+id);
export const getStorageAttId = (id) => ('message_att'+id);
export const getMessageDraft = (id) => {
    return sessionStorage.getItem(getStoremMessageId(id)) ?? '';
}
export const getAttachmentDraft = (id) => {
    let data = sessionStorage.getItem(getStorageAttId(id));
    if (data) {
        return JSON.parse(data);
    }
    return [];
}
export const saveMessageDraft = (id, message) => {
    const storageId = getStoremMessageId(id);

    if (message.length > 0) {
        sessionStorage.setItem(storageId, message);
    }
    else {
        sessionStorage.removeItem(storageId);
    }
}

export const saveAttachmentDraft = (id, att) => {
    const storageAttId = getStorageAttId(id);

    if (att.length > 0) {
        sessionStorage.setItem(storageAttId, JSON.stringify(att));
    }
    else {
        sessionStorage.removeItem(storageAttId);
    }
}
