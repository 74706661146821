import {useState} from 'react';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {validateEmail, validatePassword} from "../../helpers/Validators";
import Loader from '../UI/Loader/Loader';
import Options from './Screens/Options';
import classes from './Login.module.scss';
import FacebookLogin from "../UI/FacebookLogin/FacebookLogin";
import {useDispatch, useSelector} from "react-redux";
import {modalActions} from "../../store/modal";
import {loginWithCredentials} from "../../store/auth";
import GoogleLoginButton from "../GoogleLoginButton";
import {flavor} from "../../config/Flavor";

const Login = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const dispatch = useDispatch();
    const [screen, setScreen] = useState('options');
    const [showPassword, setShowPassword] = useState(false);
    const [loginInProgress, setLoginInProgress] = useState(false);
    const [error, setError] = useState(null);
    const [fieldErrors, setFieldErrors] = useState({email: null, password: null});

    const popupView = useSelector(state => state.modal.data);
    if (popupView && popupView.openLogin && screen === 'options') {
        setScreen('login');
    }

    const checkEmailError = (e) => {
        if (fieldErrors.email && validateEmail(e.target.value)) {
            const err = {...fieldErrors};
            err.email = null;
            setFieldErrors(err);
        }
    }
    const checkPasswordError = (e) => {
        if (fieldErrors.password && validatePassword(e.target.value)) {
            const err = {...fieldErrors};
            err.password = null;
            setFieldErrors(err);
        }
    }
    const validateData = (data) => {
        const err = {...fieldErrors};
        err.password = !validatePassword(data.password);
        err.email = !validateEmail(data.email);
        setFieldErrors(err);

        return (!err.password && !err.email);
    }
    const onFinish = (erroMsg = null) => {
        setLoginInProgress(false);
        setError(erroMsg);
    }

    const onSuccess = () => {
        onFinish();
        dispatch(modalActions.close());
    }

    const initFormSubmit = (e) => {
        e.preventDefault();
        setError(null);

        // It's in progress
        if (loginInProgress) {
            return;
        }

        const data = {
            email: e.target.email.value,
            password: e.target.password.value,
        };

        const isValid = validateData(data);
        if (isValid) {
            setLoginInProgress(true);
            initLoginCall(data);
        }
    }

    const initLoginCall = async (data) => {
        data.googleRecaptchaToken = (executeRecaptcha) ? await executeRecaptcha('tg_react_login') : null;
        dispatch(loginWithCredentials(data, onSuccess, onFinish));
    }

    const showLogin = () => {
        setScreen('login');
    }

    const togglePassword = () => {
        setShowPassword(prevState => {
            return !prevState
        })
    }

    const openPasswordReminder = (e) => {
        e.preventDefault();
        dispatch(modalActions.open({name: 'passwordReminder'}));
    }

    const inputPasswordType = showPassword ? 'text' : 'password';
    const classEmail = (fieldErrors.email) ? classes.Errored : null;
    const classPassword = (fieldErrors.password) ? classes.Errored : null;

    return (
        <div className={classes.AreaJoinUs}>
            {screen === 'options' ? <Options showRegistration={() => dispatch(modalActions.open({name: 'registration'}))} showLogin={showLogin} /> : null}
            {screen === 'login' ? (
                <div className={classes.OptionsScreen}>
                    <div className={classes.Title}>Login</div>
                    <GoogleLoginButton />
                    <FacebookLogin />
                    <div className={classes.Or}>or</div>

                    <form className={classes.LoginForm} onSubmit={initFormSubmit}>
                        {error ? <div className={classes.SubmitError}>{error}</div> : null}
                        <div className={[classes.InputLabel].join(' ')}>
                            E-mail
                            {fieldErrors.email ? <div className={classes.InputError}>Incorrect email address!</div> : null}
                        </div>
                        <input tabIndex={1} type="email" name="email" placeholder="Email address" className={classEmail} onChange={checkEmailError} />

                        <div className={[classes.InputLabel, classes.InputLabelPassword].join(' ')}>
                            Password
                            {fieldErrors.password ? <div className={classes.InputError}>Password is too short!</div> : null}
                        </div>
                        <div className={classes.PasswordArea}>
                            <input tabIndex={2} type={inputPasswordType} name="password" placeholder="Password" className={classPassword} onChange={checkPasswordError} />
                            <button type="button" onClick={togglePassword}><i className="tg-icon icon-eye"></i></button>
                        </div>

                        <button tabIndex={4} onClick={openPasswordReminder} className={classes.ForgotPassword} type="button">Forgot password?</button>
                        <button tabIndex={3} className={[classes.AuthButton, classes.LoginAction].join(' ')} type="submit">
                            {loginInProgress ? <Loader white={true} /> : 'Log in'}
                        </button>

                        <div className={classes.Bottom}>
                            <div className={classes.NewOnTravelgirlsText}>New to {flavor.domainName}?</div>
                            <button className={classes.JoinUsNow} onClick={() => dispatch(modalActions.open({name:'registration'}))} type="button">Join us now!</button>
                        </div>
                    </form>
                </div>
            ) : null}
        </div>
    );
}

export default Login;
