import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

import Classes from "./Classes.module.scss";
import {ALLOW_PUSH_SCREEN} from "../../config/Constants";
import {authActions} from "../../store/auth";
import {isPushSupported} from "../../hooks/useFcmCheck";

const AllowPushModal = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const isEnabled = isPushSupported();
    const isFetched = useSelector(s => s.auth.fetchOnInit);
    const isOpenedModal = useSelector(s => s.modal.isOpened);
    const user = useSelector(s => s.auth.user);
    const [ask, setAsk] = useState(false);

    useEffect(() => {
        if (!isFetched || !isEnabled || !user) return;
        if (location.pathname === '/onboarding') return;
        if (isOpenedModal) return;
        if (Notification.permission !== 'default') return;

        const showModal = () => {
            const closedAt = localStorage.getItem(ALLOW_PUSH_SCREEN);
            if (!closedAt) return true;

            const closeTime = new Date(parseInt(closedAt));
            const currentTimestamp = Date.now();
            const threeDaysAgoTimestamp = currentTimestamp - (3 * 24 * 60 * 60 * 1000); // 3 days in milliseconds
            return (closeTime.getTime() < threeDaysAgoTimestamp);
        }
        if (!showModal()) return;

        setAsk(true);
    }, [isEnabled, isFetched, location.pathname, user]);

    if (!ask) return;

    const onClose = () => {
        setAsk(false);
        const currentTimestamp = Date.now();
        localStorage.setItem(ALLOW_PUSH_SCREEN, String(currentTimestamp));
    }

    const onAllow = () => {
        onClose();
        Notification.requestPermission().then((permission) => {
            dispatch(authActions.grantPermission());
        });
    }

    return (
        <div className={Classes.Container}>
            <div className={Classes.Notification}>
                <i className="tg-icon icon-bell" />
                <p>Receive new message and profile views notifications</p>
            </div>

            <div className={Classes.Buttons}>
                <button onClick={onClose} className={Classes.DenyButton}>Later</button>
                <button onClick={onAllow} className={["ButtonRounded", "ButtonRoundedBig"].join(' ')}>Allow</button>
            </div>
        </div>
    )
}

export default AllowPushModal;
