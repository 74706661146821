import React, {useState} from 'react';
import Slider from "@mui/material/Slider";
import {styled} from '@mui/material/styles';
import {isStandAloneVersion} from "../../helpers/BrowserVersion";

const MuiSlider = styled(Slider)({
    color: '#000',
    margin: '10px 0',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-rail': {
        backgroundColor: '#868686',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        fontSize: 14,
        fontWeight: 'normal',
        top: -6,
        backgroundColor: 'unset',
        '& *': {
            background: 'transparent',
            color: '#000',
            fontWeight: 'bold',
        },
    },
});

const MuiSliderComponent = ({onChange, onScale, values, min, max}) => {
    const [value, setValue] = useState(values);

    const handleChange = (event, newValue) => {
        const isStandalone = isStandAloneVersion();
        if (isStandalone && event.type === 'mousedown') {
            return;
        }
        onChange(newValue)
        setValue(newValue);
    };


    return (
        <MuiSlider
            value={value}
            min={min}
            max={max}
            scale={onScale}
            onChange={handleChange}
            valueLabelDisplay="on"
        />
    );
};

export default MuiSliderComponent;

