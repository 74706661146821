import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import reactGA from "react-ga4";


import {googleAnalyticsId} from "../config/Config";


const gaEnabled = process.env.NODE_ENV !== 'development';

const GoogleAnalytics = () => {
    const location = useLocation();

    useEffect(() => {
        if (gaEnabled) {
            reactGA.initialize(googleAnalyticsId);
        }
    }, []);

    useEffect(() => {
        if (gaEnabled) {
            reactGA.send("pageview");
        }
    }, [location.pathname]);
    // End of Google Analytics

    return null;
}

export default GoogleAnalytics;
