import {useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';
import {flavor} from "../../config/Flavor";

import classes from './Agreement.module.scss';
import axios from "../../config/Axios";

const ContentAgreement = (props) => {
    const nav = useNavigate();
    const user = useSelector(state => state.auth.user);

    const saveAgreement = (action) => {
        if (action === 'disagree') {
        }
        axios.post('user/'+user.id+'/content-agreement', {'action':action});
        props.modalClosed();
    }

    const goTo = (url) => {
        nav(url);
        props.modalClosed();
    }

    return (
        <div className={classes.Agreement}>
            <h2 className={classes.Title}>CONTENT PROVIDER AGREEMENT</h2>
            <div className={classes.ContentContainer}>
                <div className={classes.SimpleText}>The Content Provider Agreement applies to all individuals, who use of our website located at {flavor.domainName} (hereinafter - Site) and constitutes a binding, legal agreement between the Users i. e. you and {flavor.domainName}</div>
                <div className={classes.SimpleText}><b>It is agreed that by using the Site you acknowledge and agree that before using our Site you have had sufficient chance to read and understand the <a onClick={() => goTo('/terms-and-conditions')} >Terms and Conditions</a> and <a onClick={()=> goTo('/privacy-policy')}>Privacy Policy</a> and you agree to be bound by them. If you do not agree, please do not use the Site.</b></div>
                <div className={classes.SimpleText}>If, for any reason, we believe that you have not complied with these requirements, we may, at our discretion, cancel your access to the Site immediately and without giving you any advance notice.</div>
                <div className={classes.SimpleText}>You can contact us using the “Contact us“ icon at the Site, if any questions.</div>
                <div className={classes.SimpleText}>HEREBY, I confirm that I am over 18 years old.<br />HEREBY, I confirm that I read and agree with TERMS AND CONDITIONS and PRIVACY POLICY.</div>
                <div className={classes.SaveArea}>
                    <div className={classes.ButtonsContainer}>
                        <button type="button" className={classes.Agree} onClick={() => {saveAgreement('agree')}}>Agree</button>
                        <button type="button" className={classes.Disagree} onClick={() => {saveAgreement('disagree')}}>Disagree</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContentAgreement;
