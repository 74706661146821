import YouTube from 'react-youtube';
import classes from './TgVideo.module.scss';
import {isTravelGirls} from "../../config/Flavor";

const onReady = (e) => {
    //e.target.pauseVideo();
}

const TgVideo = (props) => {
    const intViewportWidth = (window.innerWidth < 640 ) ? (window.innerWidth - 20) : 640;
    const videoHeight = (intViewportWidth < 640) ? Math.floor(intViewportWidth / 1.75) : 360;
    const opts = {
        width: intViewportWidth,
        height: videoHeight,
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };

    return (
        <YouTube containerClassName={classes.VideoContainer} videoId={isTravelGirls?"GlkwFlS-KLA":'aQ07FzLekz0'} opts={opts} onReady={onReady} />
    )};

export default TgVideo;
