import React from 'react';

import ImgSunbathing from "../styles/media/v3/sunbathing.png";
import ImgMountains from "../styles/media/v3/mountains.png";
import ImgCouple from "../styles/media/v3/dinner.png";

import ImgBeach from "../styles/media/v4/beach.png";
import ImgModel from "../styles/media/v4/girlModel.png";
import ImgLagoon from "../styles/media/v4/lagoon.png";
import Classes from "./ImageSection.module.scss";

const ImageSectionSecond = ({version}) => {

    const Img1 = version === 3 ? ImgSunbathing : ImgBeach;
    const Img2 = version === 3 ? ImgMountains : ImgModel;
    const Img3 = version === 3 ? ImgCouple : ImgLagoon;

    return (
        <div className={Classes.Root}>
            <div className={Classes.ImageItem}><img src={Img1} alt='' loading='lazy'/></div>
            <div className={Classes.ImageItem}><img src={Img2} alt='' loading='lazy'/></div>
            <div className={Classes.ImageItem}><img src={Img3} alt='' loading='lazy'/></div>
        </div>
    );
};

export default ImageSectionSecond;
