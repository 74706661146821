import {useState, useEffect} from "react";
import {useDispatch} from "react-redux";

import Loader from '../Loader/Loader';
import classes from './FacebookLogin.module.scss';
import {facebookAppId, facebookAppVersion, facebookScope} from "../../../config/Config";
import {modalActions} from "../../../store/modal";
import {loginWithFacebook} from "../../../store/auth";

const loadFacebookScript = (callback) => {
    const existingScript = document.getElementById('fbSDKScript');

    if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://connect.facebook.net/en_US/sdk.js';
        script.id = 'fbSDKScript';
        document.body.appendChild(script);

        script.onload = () => {
            if (callback) callback();
        };
    }

    if (existingScript && callback) {
        callback();
    }
};

const FacebookLoginButton = () => {
    const dispatch = useDispatch();
    const [isLoaded, setLoaded] = useState(false);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        loadFacebookScript(() => {
            setLoaded(true);
            window.fbAsyncInit = function() {
                window.FB.init({
                    appId: facebookAppId,
                    autoLogAppEvents: true,
                    xfbml: true,
                    version: facebookAppVersion
                });
            };
        });
    }, []);

    const onSuccess = () => dispatch(modalActions.close());

    const initFbLogin = (token, socalId) => {
        dispatch(loginWithFacebook(token, onSuccess, () => setLoading(false)));
    }

    const onLogin = () => {
        if (isLoading) {
            return;
        }
        setLoading(true);

        window.FB.login((r) => {
            setLoaded(true);
            if (r.status === 'connected') {
                initFbLogin(window.FB.getAuthResponse().accessToken, window.FB.getAuthResponse().userID);
            }
        }, {scope: facebookScope});
    };

    const classList = (!isLoaded) ? [classes.FbButton, classes.Disabled] : [classes.FbButton];
    return (
        <button
            onClick={onLogin}
            disabled={!isLoaded}
            className={classList.join(' ')}>{isLoaded && !isLoading ? 'Continue with Facebook' : <Loader white />}
        </button>
    );
}

export default FacebookLoginButton;
