import {Fragment, useEffect, useState} from "react";

import axios from '../../../../config/Axios';
import classes from './GirlsList.module.scss';
import Loader from '../../../../components/UI/Loader/Loader';
import {getCountryName} from '../../../../helpers/Countries';
import aggPhoto from '../../../../helpers/Photos';
import arrowImg from '../assets/arrow.png';
import { aggTripDate } from "../../../../helpers/Dates";

const GirlsGallery = ({onShowRegistration}) => {
    const [list, setList] = useState(null);

    const fetchGirls = () => {
        axios.get('landings/dubaiTrips?limit=7&from=12').then((r) => {
            setList(r.data.list);
        });
    };

    useEffect(() => {
        fetchGirls();
    }, []);

    return (
        <div className={classes.GirlsGalleryWrapper}>
            {list === null
                ? <Loader/>
                : (
                    <Fragment>
                        <div className={classes.Title}>🇦🇪 Travelers who plan or wants to visit Dubai soon</div>
                        <div className={classes.List}>
                            {list.map((item, id) => {
                                return (
                                    <div onClick={onShowRegistration} key={id} className={classes.User}>
                                        <img src={aggPhoto(item.user.photo, 200)} alt={''}/>
                                        <div className={classes.AreaName}>
                                            <span className={classes.Name}>{item.user.name}</span>
                                        </div>
                                        <div className={classes.Country}><div className={classes.CountryName}>{getCountryName(item.user.location.countryCode)}</div><img className={classes.Arrow} src={arrowImg} alt="arrow" /> Dubai</div>
                                        {item.date && <div className={classes.Date}>{aggTripDate(item.date.from)} - {aggTripDate(item.date.to)}</div>}
                                    </div>
                                )
                            })}
                        </div>
                    </Fragment>
                )
            }
        </div>
    );
}

export default GirlsGallery;
