import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";

import classes from '../Login.module.scss';
import FacebookLogin from "../../UI/FacebookLogin/FacebookLogin";
import {modalActions} from "../../../store/modal";
import GoogleLoginButton from "../../GoogleLoginButton";
import {flavor} from "../../../config/Flavor";

const Options = (props) => {
    const dispatch = useDispatch();
    const close = () => dispatch(modalActions.close());

    return (
        <div className={classes.OptionsScreen}>
            <div className={classes.Title}>Join us</div>
            <div className={classes.Slogan}>Find a travel mate today!</div>
            <GoogleLoginButton />
            <FacebookLogin />
            <div className={classes.Or}>or</div>
            <div className={classes.AuthActions}>
                <button onClick={props.showRegistration} className={classes.AuthButton}>Register</button>
                <span className={classes.Spacer}></span>
                <button onClick={props.showLogin} className={[classes.AuthButton, classes.AuthButtonGrey].join(' ')}>Log in</button>
            </div>
            <div className={classes.TC}>
                I agree to the {flavor.domainName} <Link to={'/terms-and-conditions'} onClick={close}>
                Terms and conditions</Link> and <Link to={'/privacy-policy'} onClick={close}>
                Privacy Policy</Link>.
            </div>
        </div>
    )
}

export default Options;
