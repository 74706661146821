import { useDispatch } from "react-redux";
import { modalActions } from "../../../../../store/modal";
import Classes from "./Button.module.scss"
import classNames from "classnames";

const Button = ({children, fromHeader = false, additionalStyle}) => {
    const dispatch = useDispatch();
    return (
        <button
            className={classNames(Classes.Button, fromHeader && Classes.ButtonHeader, additionalStyle)}
            onClick={() => dispatch(modalActions.open({name: 'login'}))}>
                {children}
        </button>
    )
}

export default Button;
