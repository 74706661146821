import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";


import {socket, socketListen, socketStopListen, socketDisconnect} from "../services/socket";
import {refetchUser} from "../store/auth";

const AppSocket = ({userId, rtmToken}) => {
    const dispatch = useDispatch();
    const [isConnected, setConnected] = useState(socket.connected);

    useEffect(() => {
        const onConnect = () => setConnected(true);
        const onDisconnect =() => setConnected(false);

        socket.auth = {token: rtmToken};
        socket.connect();

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socketDisconnect();
        };
    }, []);

    useEffect(() => {
        if (isConnected) {
            socketListen({eventType: 'newConversation'}, () => {dispatch(refetchUser(userId))});
        }

        return () => {
            socketStopListen({eventType: 'newConversation'}, () => {dispatch(refetchUser(userId))});
        }
    }, [isConnected]);

    return null;
}

export default AppSocket;
