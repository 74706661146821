import classes from './GenderCheckbox.module.scss';

const genderCheckbox = (props) => {
    const classList = [classes.Circle];
    if (props.checked) {
        // At this moment same class for all.
        const checkedClass = (props.text === 'Girl') ? classes.Checked : classes.CheckedMale;
        classList.push(checkedClass);
    }

    return (
        <button type="button" className={classes.Area} onClick={props.setAction}>
            {props.text}
            <div className={classList.join(' ')}>
                <i className="tg-icon icon-tick"></i>
            </div>
        </button>
    )};

export default genderCheckbox;
