import {useDispatch} from "react-redux";
import {modalActions} from "../../store/modal";

import * as cs from '../../config/Constants';
import classes from './PhotoUploadInfo.module.scss';

const PhotoUploadInfo = () => {
    const dispatch = useDispatch();
    const onSubmit = () => {
        localStorage.setItem(cs.PHOTO_CHECK, 'true');
        dispatch(modalActions.close());
    }

    return (
        <div className={classes.PhotoUploadInfo}>
            <h1>Add photos to your profile</h1>
            <p>
                Please upload natural and travel related photos.
                Professional photo shoots or photoshopped images are not welcome.
                The administrator can change your main photo.
                We care about the site's general image.
            </p>
            <b>You are not allowed to upload:</b>
            <ul className={classes.List}>
                <li><i className="tg-icon icon-tick"></i>Photos of a child</li>
                <li><i className="tg-icon icon-tick"></i>Animal photos</li>
                <li><i className="tg-icon icon-tick"></i>Images of celebrities</li>
                <li><i className="tg-icon icon-tick"></i>Copyrighted material</li>
                <li><i className="tg-icon icon-tick"></i>Explicit photos</li>
                <li><i className="tg-icon icon-tick"></i>Photos without you</li>
                <li><i className="tg-icon icon-tick"></i>Group photos</li>
            </ul>
            <div className={classes.Actions}>
                <div className={classes.DoNotShow}>
                    <label className='Checkbox'>
                        Do not show again
                        <input name="show" type="checkbox" value="1" /><span className="Checkmark"></span>
                    </label>
                </div>
                <button className={'ButtonRounded ButtonRoundedBig'} onClick={onSubmit}>I understand</button>
            </div>
        </div>
    )
};

export default PhotoUploadInfo;
