import {createSlice} from "@reduxjs/toolkit";
import axios from "../config/Axios";
import {conversationsActions, markAsRead} from "./conversations";

const initialState = {
    wasMounted: false,
    fromConversations: false,
    id: null,
    user: null,
    messages: [],
    hasMore: false,
    isLoading: false,
    errorOnLoading: false
}

const conversation = createSlice({
    name: 'conversation',
    initialState: initialState,
    reducers: {
        errorOnOpen(state) {
            state.wasMounted = true;
            state.isLoading = false;
            state.errorOnLoading = true;
        },
        open(state, action) {
            state.wasMounted = false;
            state.fromConversations = true;
            state.id = action.payload.user.id;
            state.user = action.payload.user;
            state.messages = []
            state.hasMore = false;
        },
        toggleFavorite(state, action) {
            state.user.isFavorited = action.payload.isFavorited;
        },
        remove(state) {
            return {
                ...initialState
            }
        },
        setMessages(state, action) {
            let newList = [...state.messages, ...action.payload.messages];

            state.wasMounted = true;
            state.isLoading = false;
            state.messages = newList;
            state.hasMore = action.payload.hasMore;
        },
        getMore(state) {
            state.isLoading = true;
        },
        prependMessage(state, action) {
            const isInserted = state.messages.find(m => m.id === action.payload.message.id);
            if (!isInserted) {
                state.messages = [action.payload.message, ...state.messages];
            }
        },
        updateSentMessage(state, action) {
            state.messages = state.messages.map(m => {
                if (m.id === null && typeof m.tmpId !== "undefined" && m.tmpId === action.payload.tmpId) {
                    return action.payload;
                }
                return m;
            });
        },
        markAsSeen(state, action) {
            if (state.messages[0].senderId === action.payload.id) {
                state.messages[0].isSeen = true;
            }
        },
        updateMessageWithReaction(state, action) {
            const message = state.messages.find(m => m.id === action.payload.id);
            if (message) {
                message.emotion = action.payload.emotion;
            }
        }
    }
});

export const setEmojiToMessage = (id, emotion, userId, senderId) => {
    return dispatch => {
        dispatch(conversationActions.updateMessageWithReaction({id, emotion}));
        axios.post(`/conversation/${userId}/${senderId}/${id}/emotion`, {emotion});
    }
}

export const removeConversation = (userId, converserId) => {
    return dispatch => {
        dispatch(conversationActions.remove());
        axios.post('/conversation/'+userId+'/'+converserId+'/remove');
    }
}

export const ignoreConversation = (userId, converserId) => {
    return dispatch => {
        dispatch(conversationActions.remove());
        axios.post('/conversation/'+userId+'/'+converserId+'/ignore');
    }
}

export const restoreConversation = (userId, converserId) => {
    return dispatch => {
        dispatch(conversationActions.remove());
        axios.post('/conversation/'+userId+'/'+converserId+'/restore');
    }
}

export const conversationFetchAndOpen = (userId, converserId) => {
    return dispatch => {
        axios.get('/conversation/'+userId+'/'+converserId)
            .then(r => {
                if (typeof r.data.error !== "undefined") {
                    dispatch(conversationActions.errorOnOpen());
                }
                else {
                    dispatch(conversationActions.open({user: r.data.user}));
                    dispatch(conversationActions.setMessages({messages: r.data.messages, hasMore: r.data.hasMore}));
                    if (r.data.isUnread) {
                        dispatch(markAsRead(parseInt(converserId)));
                    }
                }
            });
    }
}

export const conversationOpen = (userId, data, isUnread) => {
    return dispatch => {
        dispatch(conversationActions.open({user:data.user}));
        dispatch(getMessages(userId, data.id, null, isUnread));
    }
}

export const getMessages = (userId, converserId, lastMessageId = null, isUnread = false) => {
    return dispatch => {
        let params = {};

        if (lastMessageId) {
            params['lastMessageId'] = lastMessageId;
        }

        if (isUnread) {
            params['setAsRead'] = true;
        }

        const searchQueryString = Object.entries(params).length > 0
            ? ('?' + new URLSearchParams(params).toString())
            : '';

        axios.get('/conversation/'+userId+'/'+converserId+'/messages'+searchQueryString)
            .then(r => {
                dispatch(conversationActions.setMessages(r.data));
                //dispatch(setAsRead(converserId));

                if (isUnread) {
                    //dispatch(decrementConversationsCounter());
                }
            }).catch(error => {});
    }
}

export const getMoreMessages = (userId, converserId) => {
    return (dispatch, getState) => {
        const isLoading = getState().openedConversation.isLoading;

        if (isLoading) {
            return;
        }

        const messages = getState().openedConversation.messages;
        const lastId = (messages.length > 0) ? messages[messages.length-1]['id'] : null;

        dispatch(conversationActions.getMore());
        dispatch(getMessages(userId, converserId, lastId));
    }
}

export const sendMessage = (senderId, recipientId, content, attachments = []) => {
    return dispatch => {
        const tm = Math.floor(Date.now() / 1000);
        let attIds = attachments.map((item) => {return item.id});
        const message = {
            tmpId:  tm,
            id: null,
            senderId: senderId,
            content: content,
            sentAt: tm,
            attachments: attachments,
            attachmentIds: attIds,
            isNew: true
        };


        dispatch(conversationActions.prependMessage({message: message}));
        dispatch(sendAction(senderId, recipientId, message));
    }
}

const sendAction = (senderId, recipientId, message) => {
    return dispatch => {
        if ( (message.content === null || message.content === '') && message.attachments.length === 0 ) {
            return null;
        }

        axios.post('/conversations/'+senderId+'/'+recipientId+'/send', message)
            .then(r => {
                dispatch(conversationActions.updateSentMessage({...r.data, tmpId: message.tmpId}));
                dispatch(conversationsActions.updateOnSend({id: recipientId, message: r.data}));
            });
    }
}

export const conversationActions = conversation.actions;
export default conversation.reducer;
