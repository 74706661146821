import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { modalActions } from '../../store/modal';
import classes from './PhotoLimit.module.scss';
import unlimitedPhotos from './unlimited-photo.png';

const PhotoLimit = () => {
    const nav = useNavigate();
    const dispatch = useDispatch();
    const goToMembership = () => {
        dispatch(modalActions.close());
        nav({pathname: '/membership'});
    }
    return (
        <div className={classes.PhotoLimit}>
            <h1>You've Reached Your Photo Limit!</h1>
            <img className={classes.BackgroundImg} src={unlimitedPhotos} alt="unlimited photos" />

            <p className={classes.SimpleText}>Upload an Unlimited Number of Photos to Your Profile and Unlock 5 Incredible Features</p>
            <div className={classes.Line}></div>
            <div className={["ButtonRounded", "ButtonRoundedDiamond", classes.Button].join(' ')} onClick={goToMembership}>See features</div>
        </div>
    )
};

export default PhotoLimit;
