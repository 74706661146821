import axios from 'axios';
import * as Sentry from "@sentry/react";


import {APP_USER_ID, APP_UPDATE_TIME} from "./Constants";
import {isProduction} from "../helpers/DeveloperMode";
const allowedErrorCodes = [400, 401];
const apiBaseURL = '/apiv1';

// Init Axios instance
const instance =  axios.create({baseURL: apiBaseURL});


const checkAppVersion = headers => {
    if (!isProduction) {
        return;
    }

    const latestVersionFromApi = convertVersionToInt(headers['app-version']);
    const appVersion = convertVersionToInt(process.env.REACT_APP_VERSION);

    if (latestVersionFromApi > appVersion) {
        const tm = sessionStorage.getItem(APP_UPDATE_TIME);
        const appUpdateTime = tm ? new Date(Number(tm)).getTime() : null;
        const currentTime = new Date().getTime();

        if (!appUpdateTime || (appUpdateTime + (60 * 2 * 1000)) < currentTime) {
            sessionStorage.setItem(APP_UPDATE_TIME, currentTime.toString());

            setTimeout(() => {
                window.location.reload();
            }, 1500)
        }
    }
}

const convertVersionToInt = version => {
    if (typeof version === "undefined" || !version) {
        return null;
    }

    return Number(version.replace(/[^0-9]/g, ""));
}

// Add a request interceptor
instance.interceptors.request.use( (config) => {
    // Do something before request is sent
    config.headers['tg-web-app'] = '1';
    return config;

}, function (error) {
    //Sentry.captureException(error);
    Sentry.captureMessage('Error on axios.request.interceptor', (error?.message ?? ''));
    return Promise.reject(error);
});

instance.interceptors.response.use((r) => {
    checkAppVersion(r.headers);
    return r;
}, (error) => {
    const statusCode = error.response ? error.response.status : null;

    if (!allowedErrorCodes.includes(statusCode)) {
        Sentry.captureException(error);
    }

    if (statusCode === 401) {
        localStorage.removeItem(APP_USER_ID);
        window.location = '/?session=expired';
    }
    else {
        return Promise.reject(error);
    }
});

export default instance;
