import {NavLink} from "react-router-dom";


import axios from "../../../config/Axios";
import classes from './Gdpr.module.scss';
import {useState, useEffect} from "react";


const storage = window.localStorage;


const Gdpr = () => {
    const cookieName = 'cgdpr';
    const localStorageName = cookieName;
    const isClosed = storage.getItem(localStorageName);
    const [showGdpr, setShowGdpr] = useState(false);

    useEffect(() => {
        if (document.cookie.indexOf((cookieName+'=')) < 0 && !isClosed) {
            setShowGdpr(true);
        }
    }, []);

    const closeGdprHandler = (event) => {
        event.preventDefault();

        const expiry = new Date();
        expiry.setTime(expiry.getTime()+(100000000 * 1000));

        // Set as closed in local storage
        storage.setItem(localStorageName, '.');

        // Set as closed with cookie (required api request, otherwise safari browser doesnot care about expiration date)
        axios.post('cookies/gdpr');
        setShowGdpr(false);
    }

    if (showGdpr) {
      return (
          <div className={classes.gdprLine}>
              <div className={classes.insideLine}>
                  <div
                       className={classes['area-gdpr-text']}>In order to ensure appropriate functioning of our website we use cookies.
                      You can delete recorded cookies at any time. Learn more about cookies in Privacy Policy.
                  </div>
                  <div className={classes['area-gdpr-buttons']}>
                      <NavLink className={classes.link} to="/privacy-policy">Privacy Policy</NavLink>
                      <div className={classes.closeBtn} onClick={closeGdprHandler}>×</div></div>
              </div>
          </div>)
    } else {
        return null;
    }
}

export default Gdpr;
