import classes from './MainContent.module.scss';

const mainContent = (props) => {

    const classList = [classes.MainContent];

    if (props.fullWidth) {
        classList.push(classes.FullWidth);
    }
    else {
        if (!props.isVisibleSidebar) {
            classList.push(classes.WithoutSidebar);
        } else {
            if (!props.hideMainContentSpacer) {
                classList.push(classes.MainContentSpacer20);
            }
        }
    }

    return (
        <div className={classList.join(' ')}>
            {props.children}
        </div>
    )
}

export default mainContent;
