import Logo from "../assets/logo.png";
import Classes from "./DubaiWeb.module.scss";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../store/modal";

const Header = () => {
    const dispatch = useDispatch();
    return (
        <div className={Classes.Header}>
            <div className={Classes.HeaderWrapper}>
                <img src={Logo} className={Classes.Logo} alt={"Travelfriend Logo"} />
                <button className={`${Classes.LoginBtn} ButtonRounded`} onClick={() => dispatch(modalActions.open({name: 'login'}))}>Log in</button>
            </div>
        </div>
    );
};

export default Header;
