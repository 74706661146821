import {useSelector} from "react-redux";
import {isCustomLogged} from "../../../helpers/DeveloperMode";

const BackToState = () => {
    const user = useSelector(state => state.auth.user);
    const userId = user ? user.id : null;
    const isCustom = isCustomLogged();

    if (!isCustom || !userId) {
        return;
    }

    return (
        <div
            style={{
                position:"absolute",
                background: 'rgb(93 157 102 / 36%)',
                borderRadius: '10px',
                padding: '10px',
                top: '50px'
        }}>Browsing as user</div>
    );
}


export default BackToState;
