import {publicDevMode} from "./DeveloperMode";
import thumbDeleted from "../assets/thumbs/deleted.svg";
import thumbFemale from "../assets/thumbs/female.svg";
import thumbFemaleHidden from "../assets/thumbs/female-hidden.svg";
//import thumbFemalePrivate from "../assets/thumbs/female-locked.svg";
import thumbMale from "../assets/thumbs/male.svg";
import thumbMaleHidden from "../assets/thumbs/male-hidden.svg";
//import thumbMalePrivate from "../assets/thumbs/male-locked.svg";


export const getMainAggregated = (user, sideWidth) => {
    if (!user) {
        return thumbDeleted;
    }

    if (user.isHidden) {
        return (user.sex === 'female') ? thumbFemaleHidden : thumbMaleHidden;
    }

    if (user.photos.length > 0) {
        const photo = user.photos.filter(p => p.photo.isMain === true)[0];
        if (photo) {
            return aggPhoto(photo['photo'], sideWidth, user);
        }
    }

    return (user.sex === 'female') ? thumbFemale : thumbMale;
}

export const getMainPhotoInMemberProfile = (user, sideWidth) => {
    if (!user) {
        return thumbDeleted;
    }

    let firstPhoto = null;
    if (user.photos && user.photos.list.length > 0) {
        if (!user.photos.list[0].isPrivate) {
            firstPhoto = user.photos.list[0];
        }
    }

    if (firstPhoto) {
        return aggPhoto(firstPhoto, sideWidth, user);
    }

    else if (user.isHidden) {
        return (user.sex === 'female') ? thumbFemaleHidden : thumbMaleHidden;
    }

    return (user.sex === 'female') ? thumbFemale : thumbMale;
}

const params = {
    //domain: 'https://dme91qht66x41.cloudfront.net', alias: '_t_', // Old configuration
    domain: 'https://d35shkxizej398.cloudfront.net',
    //dpr: (window.devicePixelRatio >= 1.5) ? 1.5 : 1,
    dpr: 1.5,
    maxSideWidth: 1080,
    maxSquare: 700,
    alias: '_a1_'
};

const aggPhoto = (photo, sideWidth, user) => {
    if (!photo) {
        if (!user || user.name === null && user.sex === null) {
            return thumbDeleted;
        }

        if (user.isHidden) {
            return (user.sex === 'female') ? thumbFemaleHidden : thumbMaleHidden;
        }

        return (user.sex === 'female') ? thumbFemale : thumbMale;
    }

    if (publicDevMode()) {
        //return 'https://dummyimage.com/600x600/c0dca5.jpg';
    }

    let maxSideWidth = params.maxSideWidth;
    let parts = [];
    parts.push('r-' + photo.angle);

    if (!sideWidth) {
        if (photo.w > photo.h) parts.push('w-' + ((photo.w <= maxSideWidth) ? photo.w : maxSideWidth));
        else parts.push('h-' + ((photo.h <= maxSideWidth) ? photo.h : maxSideWidth));
    } else {
        sideWidth = (sideWidth * params.dpr).toFixed(0);

        // Max square
        if (sideWidth > maxSideWidth) {
            sideWidth = params.maxSquare;
        }

        // Ensure that side-width is not longer than image height or width
        if (photo.w < sideWidth) sideWidth = photo.w;
        if (photo.h < sideWidth) sideWidth = photo.h;

        parts.push('w-' + sideWidth);
        parts.push('c-'+photo.coords['x1']+','+photo.coords['y1']+','+photo.coords['x2']+','+photo.coords['y2']);
    }

    var manipulateStr = parts.join('.');
    return (params.domain + '/' + manipulateStr + '/' + params.alias + '/' + photo.url);
}

export default aggPhoto;
