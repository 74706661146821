import classes from './VerifyBlocked.module.scss';
import verifyIcon from './assets/verifyIcon.png';
import cameraIcon from './assets/cameraIcon.png';
import {useNavigate} from "react-router-dom";

const VerifyBlocked = ({modalClosedForce}) => {
    const nav = useNavigate();

    const gotoVerifyUpload = () => {
        modalClosedForce();
        nav('/verification/step1');
    }

    return (
        <div className={classes.Area}>
            <img src={verifyIcon} className={classes.Icon} alt="verification icon"/>
            <h3>Please verify your profile.</h3>
            <img src={cameraIcon} className={classes.Camera} alt="camera icon"/>
            <h4>Capture a photo of your face</h4>
            <button onClick={gotoVerifyUpload} className={["ButtonRounded", "ButtonRoundedBig"].join(' ')}>Start Verification</button>
        </div>
    );
}

export default VerifyBlocked;
