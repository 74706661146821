import classes from './style.module.scss';

const SuccessIcon = () => {
    return (
        <div className={classes.successIcon}>
            <div className={classes.successIcon__tip} />
            <div className={classes.successIcon__long} />
        </div>
    );
}

export default SuccessIcon;
