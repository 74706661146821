import classes from './DubaiWeb.module.scss';

import Header from "./Header";
import Footer from '../../../Layout/Footer/Footer';
import landingWebpTf from '../assets/background-web-dubai.webp';
import landingImgTf from '../assets/background-web-dubai.jpg';
import tickImg from '../assets/tick.png';
import testimonialsImg from '../assets/testimonials.png';
import GoogleLoginButton from '../../../../components/GoogleLoginButton';
import FacebookLoginButton from '../../../../components/UI/FacebookLogin/FacebookLogin';
import videoImgTf from '../../../../assets/travelfriend-youtube.png';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../../../store/modal';
import GirlsRounded from '../Girls/GirlsRounded';
import GirlsInline from '../Girls/GirlsInline';
import GirlsGallery from '../Girls/GirlsGallery';
import GirlsTrips from '../Girls/GirlsTrips';

const DubaiWeb = ({girlsList, secondGirlsList, tripList}) => {
    const dispatch = useDispatch();

    const onClickAnyLink = () => {
        dispatch(modalActions.open({name: 'registration'}));
    }

    return (
        <div className={classes.Landing}>
            <Header />
            <picture className={classes.WebBackgroundTf}>
                <source srcSet={landingWebpTf} type="image/webp" />
                <img src={landingImgTf} className={classes.LandingImg} alt={'Main background'} />
            </picture>
            

            <div className={classes.LoginWrapper}>
                <div className={classes.Login}>
                    <h1 className={classes.Title}>Meet travelers in Dubai!</h1>
                    <h4>Travelfriend connects thousands of solo travelers across the world.</h4>
                    <GoogleLoginButton />
                    <FacebookLoginButton />
                    <div className={[classes.Center, classes.Or].join(' ')}>or</div>
                    <div className={classes.AuthActions}>
                        <button onClick={() => dispatch(modalActions.open({name: 'registration'}))} className={classes.AuthButton}>Register</button>
                        <button onClick={() => dispatch(modalActions.open({name: 'login', data: {openLogin: true} }))} className={[classes.AuthButton, classes.AuthButtonGrey].join(' ')}>Log in</button>
                    </div>
                </div>
            </div>

            <GirlsRounded onGirlClick={onClickAnyLink} list={girlsList} />

            <div className={classes.CenterWrapper}>
                <div className={classes.Row}>
                    <div className={classes.Column}>
                        <h2 className={[classes.Padding].join(' ')}>Why Should I Join Travelfriend?</h2>
                        <h5>Travelfriend is unique:</h5>
                        <div className={classes.ListItem}>
                            <div className={classes.Tick}><img src={tickImg} alt="tick"/></div>
                            <div className={classes.TextWrapper}>
                                <div className={classes.Title}>A Better Chance for Connection</div>
                                <div className={classes.Text}>Here at Travelfriend, being local is your great advantage. You're not just another face in the crowd. Travelers are looking for someone who knows the city. This means you have a much better chance of meeting interesting people, or even finding the love of your life, compared to other dating sites.</div>
                            </div>
                        </div>

                        <div className={classes.ListItem}>
                            <div className={classes.Tick}><img src={tickImg} alt="tick"/></div>
                            <div className={classes.TextWrapper}>
                                <div className={classes.Title}>Discover Interesting Travelers</div>
                                <div className={classes.Text}>Travel lovers are naturally adventurous and intriguing. Connect with individuals who are keen to explore and value the inside knowledge only a local like you can offer.</div>
                            </div>
                        </div>

                        <div className={classes.ListItem}>
                            <div className={classes.Tick}><img src={tickImg} alt="tick"/></div>
                            <div className={classes.TextWrapper}>
                                <div className={classes.Title}>Make a Difference</div>
                                <div className={classes.Text}>Assist visitors in your city and make their travel experience memorable. Many are eager to explore new places but prefer not to do it alone. Offer your local perspective and perhaps travel together to a destination you both love..</div>
                            </div>
                        </div>

                        <div className={[classes.Center, classes.VideoContainer].join(' ')}>
                            <button onClick={() => dispatch(modalActions.open({name: 'tgVideo'}))}>
                                <img src={videoImgTf} className={classes.videoPlayImg} alt={'Play Video'} />
                                <div className={classes.VideoPlayBtn}></div>
                            </button>
                        </div>

                        <p className={[classes.SubTitle, classes.Padding].join(' ')}>So, are you ready to meet someone adventurous right now? 
Join Travelfriend.com and plunge into a sea of adventure.</p>
                    </div>
                </div>
                <h3>🇦🇪 Travelers currently visiting Dubai</h3>
                <GirlsInline onShowRegistration={onClickAnyLink} list={secondGirlsList} showBackBtn={true} />
                <div className={classes.Row}>
                    <div className={classes.Column}>
                        <h2>Get to Know Travelfriend</h2>
                        <p>Founded in 2010, Travelfriend now connects over 3 million solo travelers across the globe.</p>
                        <p>It's more than just a dating site—it's a community where you can meet a variety of travelers seeking friendship, love, or even job opportunities. Find out who's coming to your city or look for a companion to join you on a journey.</p>
                        <p>We're committed to safety and authenticity. Our team carefully reviews profiles to ensure a secure and genuine meeting space. With Travelfriend, you're part of a safe and vibrant travel-dating network.</p>
                    </div>
                </div>

                <h3>🇦🇪 Travelers who have already planned a trip to Dubai</h3>
                <GirlsTrips onShowRegistration={onClickAnyLink} list={tripList} />

                <h2>Testimonials</h2>
                <h5>Are you skeptical? It’s ok. Everyone is. Check out what other members say about us.</h5>
                <img className={classes.Testimonials} src={testimonialsImg} onClick={onClickAnyLink} alt="testimonials" />

                <GirlsGallery onShowRegistration={onClickAnyLink} />
            </div>
            <div className={classes.BottomSpacer}></div>
            <Footer />
        </div>
    )
}

export default DubaiWeb;
