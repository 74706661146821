import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import MuxPlayer from "@mux/mux-player-react";

import {aggGalleryUrl} from "../../../../helpers/UserUrl";

import Classes from "./Yachts.module.scss";
import Header from "./Header/Header";
import MembersGrid from "./MembersGrid/MembersGrid";
import Button from "../Components/Button/Button";

import ImgYacht from "../styles/media/Section1/yacht-min.png";
import ImgYacht2 from "../styles/media/Section1/yacht2-min.png";
import ImgLimbo from "../styles/media/Section1/limbo-min.png";

import V2_Yacht from "../styles/media/Section1/v2/yacht.png";
import V2_Yacht2 from "../styles/media/Section1/v2/yacht2.png";
import V2_Girls from "../styles/media/Section1/v2/girls.png";

import ImgYachtGirls from "../styles/media/Section2/yaht_girls-min.png";
import ImgYahtSea from "../styles/media/Section2/yahts_and_sea-min.png";
import ImgSeaViewDrinks from "../styles/media/Section2/sea_view_drinks-min.png";

import V2_Girl from "../styles/media/Section2/v2/girl.png";
import V2_Yachts from "../styles/media/Section2/v2/yachts.png";
import V2_View from "../styles/media/Section2/v2/island_view.png";

import HeaderLight from "./Header/HeaderLight";
import Footer from "./Footer/Footer";
import Section from "../Components/Section";
import ImageSection from "../Components/ImageSection";
import ImageSectionSecond from "../Components/ImageSectionSecond";
import JoinSection from "../Components/JoinSection";

const isMobileDevice = () => {
    const cWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return 768 > cWidth;
}

const Yachts = ({version}) => {
    const nav = useNavigate();
    const user = useSelector(state => state.auth.user);
    const [isMobile, setMobile] = useState(true);

    const isLightVersion = [3, 4].includes(version);

    useEffect(() => {
        if (user) {
            nav(aggGalleryUrl(user));
        }
    }, [user, nav]);

    useEffect(() => {
        const handleWindowResize = () => {
            setMobile(isMobileDevice());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        const bodyClass = [1, 2].includes(version) ? Classes.bodyDark : Classes.bodyLight;
        document.body.classList.add(bodyClass);

        return () => {
            document.body.classList.remove(bodyClass);
        };
    }, [version]);

    return (
        <div className={Classes.Root}>
            {isLightVersion ? <HeaderLight/> : <Header/>}
            <MainContainerWithVideo version={version}/>
            {[1, 2].includes(version) && <div className={Classes.PageBackground}>
                <FirstImageContainer version={version}/>
                <SecondImageContainer version={version}/>
                <MembersGrid version={version} isMobile={isMobile}/>
            </div>}
            {[3, 4].includes(version) && <div className={Classes.PageBackgroundLight}>
                <Section version={version} isFirst/>
                <ImageSection version={version}/>
                <Section version={version}/>
                <ImageSectionSecond version={version}/>
                <div className={Classes.MembersArea}>
                    {[3, 4].includes(version) && <JoinSection version={version}/>}
                    <MembersGrid version={version} isMobile={isMobile}/>
                </div>
            </div>}

            <div className={Classes.BottomSpacer}/>
            <Footer />
        </div>
    );
}

const MainContainerWithVideo = ({version}) => {
    return (
        <div className={Classes.Container}>
            <div className={Classes.ContainerWrapper}>
                <div className={Classes.Content}>
                    {[2, 4].includes(version) &&
                        <h1 className={version === 4 ? Classes.AlternativeHeader : undefined}>Get your yacht ready for
                            extraordinary experiences</h1>}
                    {[1, 3].includes(version) &&
                        <h1 className={version === 3 ? Classes.AlternativeHeader : undefined}>Why travel solo when it's simply
                            better together?</h1>}

                    {[1, 3].includes(version) &&
                        <div className={Classes.MainSlogan}>Find a traveling friend and get the yacht ready for
                            extraordinary adventures</div>}
                    {[2, 4].includes(version) &&
                        <div className={Classes.MainSlogan}>Finding a perfect company for<br/>adventures was never that
                            easy</div>}

                    <Button additionalStyle={[3, 4].includes(version) && Classes.ButtonHeroLighter}>Join
                        Travelfriend</Button>
                </div>
                <div className={Classes.Background}/>
                <div className={Classes.DesktopVideo}>
                    <MuxPlayer
                        streamType="on-demand"
                        playbackId="h00mYHoLubpUu5IvQj400IlwnxNMcyuByvZrnGXUy6sSo"
                        thumbnailTime={0}
                        loop muted autoPlay playsInline
                        minResolution={"1080p"}
                        maxResolution={"2160p"}
                    />
                </div>
                <div className={Classes.MobileVideo}>
                    <MuxPlayer
                        streamType="on-demand"
                        playbackId="7FtoXgXh009r02vDyYTbWgI01wRjaoEsv6E2L32qhSpMfY"
                        thumbnailTime={0}
                        loop muted autoPlay playsInline
                    />
                </div>
            </div>
        </div>
    );
}

const FirstImageContainer = ({version}) => {
    const Img1 = version !== 2 ? ImgYacht2 : V2_Yacht2;
    const Img2 = version !== 2 ? ImgLimbo : V2_Girls;
    const Img3 = version !== 2 ? ImgYacht : V2_Yacht;

    return (
        <div className={[Classes.cFlex, Classes.Story, Classes.ColumnReverse].join(' ')}>
            <div className={Classes.ImageContainer1}>
                <div className={Classes.ImageItem}><img src={Img1} alt='' loading='lazy'/></div>
                <div className={Classes.ImageItem}><img src={Img2} alt='' loading='lazy'/></div>
                <div className={[Classes.ImageItem, Classes.ImageItemLong].join(' ')}><img src={Img3} alt='' loading='lazy'/></div>
            </div>
            <div className={[Classes.TextContainer, Classes.Right].join(' ')}>
                {version !== 2 &&
                    <div className={Classes.Slogan}>Travelfriend is the <i>biggest online community</i> of people who
                        are ready to have fun</div>}
                {version === 2 &&
                    <div className={Classes.Slogan}>Travelfriend is the biggest online community of people who <i>crave
                        fun</i></div>}
                <div className={Classes.Description}>With more than 2 million registered users you're just a few clicks
                    away from finding a company for your upcoming trip
                </div>
            </div>
        </div>
    );
}

const SecondImageContainer = ({version}) => {
    const Img1 = version !== 2 ? ImgYachtGirls : V2_Girl;
    const Img2 = version !== 2 ? ImgSeaViewDrinks : V2_Yachts;
    const Img3 = version !== 2 ? ImgYahtSea : V2_View;


    return (
        <div className={[Classes.cFlex, Classes.Story].join(' ')}>
            <div className={[Classes.TextContainer, Classes.Left].join(' ')}>
                {version !== 2 && (
                    <>
                        <div className={Classes.Slogan}>Travelfriend's main mission is to <i>connect people</i> so they
                            can elevate their traveling experiences
                        </div>
                        <div className={Classes.Description}>Food, laugh, champagne, summer breeze & nearly everything
                            is better when you have someone to share it with
                        </div>
                    </>
                )}

                {version === 2 && (
                    <>
                        <div className={Classes.Slogan}>Travelfriend's mission is to make sure your next trip has <i>everything
                            you've dreamt of</i></div>
                        <div className={Classes.Description}>Especially when it comes to finding a company to enjoy the
                            sun, food, drinks, and many more with
                        </div>
                    </>

                )}

                <div className={Classes.FindMatchButtonArea}>
                    <Button>Find a match</Button>
                </div>
            </div>

            {[1, 2].includes(version) && <div className={Classes.ImageContainer2}>
                <div className={Classes.Item}><img src={Img1} className={Classes.BigImage} alt='' loading='lazy'/></div>
                <div className={[Classes.Item, Classes.cFlex, Classes.cFlexColumn].join(' ')}>
                    <img src={Img2} alt='' loading='lazy'/>
                    <img src={Img3} className={Classes.LastImage} alt='' loading='lazy'/>
                </div>
            </div>}
        </div>
    );
}

export default Yachts;
