import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import classes from './Membership.module.scss';
import imgOthers from './others.png';
import stopSign from './stop-sign.svg';
import aggPhoto, {getMainAggregated, getMainPhotoInMemberProfile} from "../../helpers/Photos";
import {fireFbEvent} from "../../helpers/FbPixel";

const Membership = (props) => {
    const nav = useNavigate();
    const user = useSelector(state => state.auth.user);
    const profileWatched = useSelector(state => state.modal.data.user);

    useEffect(() => {fireFbEvent('AddToCart');}, []);

    const gotoMembership = () => {
        props.modalClosed();
        nav('/membership');
    }

    const profileWatchedPhotoUrl = (profileWatched.photo)
        ? aggPhoto(profileWatched.photo, 100, profileWatched)
        : getMainPhotoInMemberProfile(profileWatched, 100);

    return (
        <div className={classes.Area}>
            <h1>Membership</h1>
            <h2>Subscribe to chat<br/>with <b>{profileWatched.name} and all others</b>
            </h2>
            <div className={classes.ImageArea}>
                <img className={classes.ImgUser} src={getMainAggregated(user, 100)} alt={''} />
                <div className={classes.Line}>
                    <img src={stopSign} alt={''} />
                </div>
                <img className={[classes.ImgUser, classes.ImgUserToContact].join(' ')} src={profileWatchedPhotoUrl} alt={''}/>
                <img className={classes.ImgOthers} src={imgOthers} alt={''}/>
            </div>
            <button onClick={gotoMembership} className={["ButtonRounded", "ButtonRoundedBig", classes.Upgrade].join(' ')}>Upgrade</button>
            <div className={classes.Text}>
                Browse thousands of adventurous travelers.<br/>
                Contact your favoured ones right away.<br />
                Start planning your next travel date.
            </div>
            <div className={[classes.Text, classes.BottomText].join(' ')}>If you are not happy, we will return your money with no questions asked.</div>
        </div>
    )};

export default Membership;
