import {useDispatch} from "react-redux";
import {NavLink} from "react-router-dom";

import {modalActions} from "../../../../store/modal";
import classes from './DubaiMobile.module.scss';

import FacebookLogin from "../../../../components/UI/FacebookLogin/FacebookLogin";

import tfLogo from '../../../../assets/logo-travelfriend-colored.svg';
import fbIcon from '../../../../assets/landing/fb.png';
import igIcon from '../../../../assets/landing/ig.png';
import twiterIcon from '../../../../assets/landing/twiter.png'
import landingWebpTf from '../assets/background-mobile-dubai.webp';
import landingImgTf from '../assets/background-mobile-dubai.png';
import GoogleLoginButton from "../../../../components/GoogleLoginButton";
import GooglePlayButton from "../../../../components/common/GooglePlayButton";
import {flavor, isTravelGirls} from "../../../../config/Flavor";


import tickImg from '../assets/tick.png';
import testimonialsImg from '../assets/testimonials.png';
import videoImgTf from '../../../../assets/travelfriend-youtube.png';
import GirlsRounded from "../Girls/GirlsRounded";
import GirlsInline from '../Girls/GirlsInline';
import GirlsGallery from "../Girls/GirlsGallery";
import GirlsTrips from "../Girls/GirlsTrips";



const DubaiMobile = ({girlsList, secondGirlsList, tripList}) => {
    const dispatch = useDispatch();

    const onClickAnyLink = () => {
        dispatch(modalActions.open({name: 'registration'}));
    }

    return (
        <div className={classes.Landing}>
            <div className={classes.MobileHeader}>
                <div className={classes.HeaderLogo}>
                    <img src={tfLogo} alt="Travelfriend" />
                </div>
                <h1 className={classes.HeaderTitle}>Meet travelers in Dubai</h1>
                <h4>Travelfriend connects thousands of solo travelers across the world.</h4>
            </div>
            <div className={classes.MainBackgroundAndSlogan}>
                <picture>
                    <source srcSet={landingWebpTf} type="image/webp" />
                    <img src={landingImgTf} className={classes.LandingImg} alt={'Main background'} />
                </picture>

                <GirlsRounded onGirlClick={onClickAnyLink} list={girlsList} />

                <div className={classes.Login}>
                    <GoogleLoginButton />
                    <FacebookLogin />
                    <div className={classes.Or}>or</div>
                    <div className={classes.AuthActions}>
                        <button onClick={() => dispatch(modalActions.open({name: 'registration'}))} className={classes.AuthButton}>Register</button>
                        <span className={classes.Spacer}></span>
                        <button onClick={() => dispatch(modalActions.open({name: 'login', data: {openLogin: true} }))} className={[classes.AuthButton, classes.AuthButtonGrey].join(' ')}>Log in</button>
                    </div>
                </div>
                <h2 className={[classes.MobileOnly, classes.Padding].join(' ')}>Why should I join Travelfriend?</h2>
                <h5 className={[classes.MobileOnly, classes.Padding].join(' ')}>Travelfriend is unique:</h5>
                <div className={classes.ListItem}>
                    <div className={classes.Tick}><img src={tickImg} alt="tick"/></div>
                    <div className={classes.TextWrapper}>
                        <div className={classes.Title}>A Better Chance for Connection</div>
                        <div className={classes.Text}>Here at Travelfriend, being local is your great advantage. You're not just another face in the crowd. Travelers are looking for someone who knows the city. This means you have a much better chance of meeting interesting people, or even finding the love of your life, compared to other dating sites.</div>
                    </div>
                </div>

                <div className={[classes.MobileOnly, classes.Center, classes.VideoContainer].join(' ')}>
                    <button onClick={() => dispatch(modalActions.open({name: 'tgVideo'}))}>
                        <img src={videoImgTf} className={classes.videoPlayImg} alt={'Play Video'} />
                        <div className={classes.VideoPlayBtn}></div>
                    </button>
                </div>

                <p className={[classes.SubTitle, classes.MobileOnly, classes.Padding].join(' ')}>
                    So, are you ready to meet someone adventurous right now?<br />
                    Join Travelfriend.com and plunge into a sea of adventure.
                </p>

                <h3>🇦🇪 Travelers currently visiting Dubai</h3>
                <GirlsInline onShowRegistration={onClickAnyLink} list={secondGirlsList} />

                <h2 className={[classes.MobileOnly, classes.Padding, classes.MinMargin].join(' ')}>Get to Know Travelfriend</h2>

                <p className={[classes.SimpleText, classes.MobileOnly, classes.Padding].join(' ')}>Founded in 2010, Travelfriend now connects over 3 million solo travelers across the globe.</p>
                <p className={[classes.SimpleText, classes.MobileOnly, classes.Padding].join(' ')}>It's more than just a dating site - it's a community where you can meet a variety of travelers seeking friendship, love, or even job opportunities. Find out who's coming to your city or look for a companion to join you on a journey.</p>
                <p className={[classes.SimpleText, classes.MobileOnly, classes.Padding].join(' ')}>We're committed to safety and authenticity. Our team carefully reviews profiles to ensure a secure and genuine meeting space. With Travelfriend, you're part of a safe and vibrant travel-dating network.</p>

                <h3>🇦🇪 Travelers who have already planned a trip to Dubai</h3>
                <GirlsTrips onShowRegistration={onClickAnyLink} list={tripList} />

                <h2 className={classes.Centered}>Testimonials</h2>
                <h5 className={[classes.Padding, classes.Centered].join(' ')}>Are you skeptical? It’s ok. Everyone is. Check out what other members say about us.</h5>
                <img className={classes.Testimonials} src={testimonialsImg} onClick={onClickAnyLink} alt="testimonials" />

                <GirlsGallery onShowRegistration={onClickAnyLink} />
            </div>

            <div className={classes.MobileFooter}>
                <div className={`${classes.FooterInner} ${!isTravelGirls ? classes.emptyBackground : ''}`}>

                    <div className={classes.Logos}>
                        <GooglePlayButton className={classes.GoogleBadge} />
                    </div>
                    <div className={classes.Secure}>
                        <i className={['tg-icon', 'icon-lock-2', classes.Lock].join(' ')}></i>
                        <span className={classes.Text}>100% secure</span>
                        <i className={['tg-icon', 'icon-visa', classes.CreditCards].join(' ')}></i>
                        <i className={['tg-icon', 'icon-master-card', classes.CreditCards].join(' ')}></i>
                    </div>
                    <div className={classes.Social}>
                        <img src={fbIcon} alt="Social facebook" width="12" height="25" />
                        <img src={twiterIcon} alt="Social twiter" width="30" height="25" />
                        <img src={igIcon} alt="Social instagram" width="25" height="25" />
                    </div>
                    <div className={classes.Links}>
                        <NavLink to="/about-us">About {flavor.name}</NavLink>
                        <NavLink to="/faq">Need help?.</NavLink>
                        <NavLink to="/travel-tips">Travel tips</NavLink>
                    </div>
                    <div className={classes.Links}>
                        <NavLink to="/support">Contact us</NavLink>
                    </div>

                    <div className={classes.AllRights}>
                        All rights are reserved. TM Solutions Limited 2010 - {new Date().getFullYear()} © {flavor.domainName}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DubaiMobile;
