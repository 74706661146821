const select = (props) => {
    const classList = ['select'];
    if (props.cssOverride) {
        classList.push(props.cssOverride);
    }

    // Set empty event if nothing given
    const onChange = (typeof props.onChange !== "undefined") ? props.onChange : () => {};

    return (
        <div>
            <div className={[classList.join(' ')]}>
                <select name={props.name} defaultValue={props.optionSelected} disabled={props.isDisabled ? true : null} onChange={(e) => onChange(e)}>
                    {props.list.map((item, i) => {
                        let isDisabled = (typeof item.disabled === 'undefined') ? false : item.disabled;
                        return (<option key={i} value={item.value} disabled={isDisabled ? true : null}>{item.label}</option>);
                    })}
                </select>
                <span className="focus"></span>

                <div className="selectMore">
                    <i className="tg-icon icon-tick-down"></i>
                </div>
            </div>
        </div>
    )
}

export default select;
