import Classes from "./Footer.module.scss";
import classNames from "classnames";

const Footer = ({version}) => (
    <div className={classNames(Classes.Container, [3, 4].includes(version) && Classes.ContainerLight)}>
        <div className={classNames(Classes.Links, [3, 4].includes(version) && Classes.LinksDarker)}>
            <a href={'/travel-tips'}>Travel Tips</a> |&nbsp;
            <a href={'/terms-and-conditions'}>Terms and Conditions</a> |&nbsp;
            <a href={'/privacy-policy'}>Privacy Policy</a> |&nbsp;
            <a href={'/support'}>Support</a>
        </div>
        <div>All rights are reserved. 2023 © travelfriend.com</div>
    </div>
);

export default Footer;
