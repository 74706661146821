import {createSlice} from "@reduxjs/toolkit";
import axios from "../config/Axios";
import {countersActions} from "./counters";
import {isCustomLogged} from "../helpers/DeveloperMode";

const initialState = {
    isInitiated: false,
    isLoading: true,
    list: [],
    hasMore: false,
    inboxType: null,
    name: '',
    scrollY: 0
}

const conversationsSlice = createSlice({
    name: 'conversations',
    initialState: initialState,
    reducers: {
        reset(state, action) {
            return {
                ...initialState,
                inboxType: action.payload.inboxType,
                name: action.payload.name
            }
        },
        startFetch(state) {
            state.isLoading = true;
        },
        fetch(state, action) {
            let newList = [...state.list, ...action.payload.list];
            state.isInitiated = true;
            state.isLoading = false;
            state.hasMore = action.payload.hasMore;
            state.list = newList;
            //state.params.from = newList.length;
        },
        setScrollPosition(state, action) {
            state.scrollY = action.payload.scrollY;
        },
        setAsRead(state, action) {
            state.list.map(c => {
                if (c.id === action.payload.id) {
                    c.isUnread = false;
                }
                return c;
            });
        },
        toggleFavorite(state, action) {
            state.list.map(c => {
                if (c.id === action.payload.userId) {
                    c.isFavorited = action.payload.isFavorited;
                    c.user.isFavorited = action.payload.isFavorited;
                }
                return c;
            });
        },
        remove(state, action) {
            let newList = state.list.filter(l => l.id !== action.payload.converserId);
            state.list = newList;
        },
        appendFromSocket(state, action) {
            const foundIndex = state.list.findIndex(c => c.id === action.payload.id);
            if (foundIndex >= 0) {
                state.list[foundIndex] = action.payload;
            }
            else {
                state.list.splice(0, 0, action.payload);
            }

            state.list.sort((a, b) => a.timeAgo < b.timeAgo ? 1 : -1);
        },
        updateOnSend(state, action) {
            state.list.map(c => {
                if (c.id === parseInt(action.payload.id)) {
                    c.isUnread = false;
                    c.content = action.payload.message.content;
                    c.timeAgo = action.payload.message.sentAt;
                }
                return c;
            });
            state.list.sort((a, b) => a.timeAgo < b.timeAgo ? 1 : -1);
        }
    }
});

export const resetConversations = (userId, inboxType = null, name = '') => {
    return async (dispatch, getState) => {
        dispatch(conversationsActions.reset({name: name, inboxType: inboxType}));

        const currentState = getState().conversations;
        const data = await apiGetConversations(userId, aggParams(currentState));
        dispatch(conversationsActions.fetch(data));
    }
}

export const markAsRead = (userId) => {
    return async(dispatch, getState) => {
        // Do not mark as read if custom-logged
        if (isCustomLogged()) {
            return;
        }

        const conversations = getState().conversations;
        const foundUnreadConversation = conversations.list.find(c => c.id === userId && c.isUnread);

        if (foundUnreadConversation) {
            dispatch(conversationsActions.setAsRead({id: userId}));
            dispatch(countersActions.decrementUnreadConversationsCounter());
        }
    }
}

export const getConversations = (userId) => {
    return async (dispatch, getState) => {
        dispatch(conversationsActions.startFetch());
        const c = getState().conversations;
        const data = await apiGetConversations(userId, aggParams(c));
        dispatch(conversationsActions.fetch(data));
    }
}

const apiGetConversations = async (userId, params) => {
    const searchQueryString = new URLSearchParams(params).toString();
    const response = await axios.get('/conversations/'+userId+'?'+searchQueryString);
    return response.data;
};

const aggParams = (currentState) => {
    const param = {};

    if (currentState.name) {
        param['name'] = currentState.name;
    }

    if (currentState.inboxType) {
        param['type'] = currentState.inboxType;
    }

    param['from'] = currentState.list.length;
    return param;
}

export const conversationsActions = conversationsSlice.actions;
export default conversationsSlice.reducer;
