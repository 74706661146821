import {configureStore} from "@reduxjs/toolkit";

import modalReducer from "./modal";
import authReducer from "./auth";
import counterReducer from "./counters";
import galleryReducer from "./gallery";
import tripsReducer from "./trips";
import mytripsReducer from "./mytrips";
import groupMessageReducer from "./groupMessage";
import visitorsReducer from './visitors';
import favoritesReducer from './favorites';
import conversationsReducer from './conversations';
import conversation from './conversation';
import openedUser from "./openedUser";

const store = configureStore({
   reducer: {
       auth: authReducer,
       modal: modalReducer,
       counters: counterReducer,
       gallery: galleryReducer,
       openedUser: openedUser,
       trips: tripsReducer,
       myTrips: mytripsReducer,
       groupMessage: groupMessageReducer,
       visitors: visitorsReducer,
       favorites: favoritesReducer,
       conversations: conversationsReducer,
       openedConversation: conversation
   }
});

export default store;
