import ReactPixel from "react-facebook-pixel";
import {facebookPixelId} from "../config/Config";

export const initFbPixel = () => {
    if (!facebookPixelId) {
        return;
    }

    ReactPixel.init(facebookPixelId);
    ReactPixel.pageView();
}

export const fireFbEvent = (eventName, data) => {
    if (!facebookPixelId) {
        return;
    }

    if (eventName === 'SignUp') {
        ReactPixel.track('CompleteRegistration', {category: 'SignUp', value: 0.00, currency: 'USD'});
    }

    if (eventName === 'AddToCart') {
        ReactPixel.track('AddToCart');
    }

    if (eventName === 'Purchase') {
        if (data && data.daysTrial > 0) {
            ReactPixel.track('StartTrial', {value: '0.00', currency: 'USD', predicted_ltv: '0.00'});
        }
        else {
            ReactPixel.track('Purchase', {category: 'Purchase', value: data.amount, currency: data.currency});
        }
    }
}
