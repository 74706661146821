import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    unreadViews: 0,
    unreadTrips: 0,
    unreadConversations: 0,
    photoRequests: 0
}

const countersSlice = createSlice({
    name: 'counters',
    initialState: initialState,
    reducers: {
        set(state, action) {
            state[action.payload.name] = action.payload.count;
        },
        updateAllCounters(state, action) {
            for (const [counterName, counterValue] of Object.entries(action.payload)) {
                state[counterName] = counterValue;
            }
        },
        decrementUnreadConversationsCounter(state) {
            state.unreadConversations = state.unreadConversations > 0 ? state.unreadConversations-1 : 0;
        }
    }
});

export const countersActions = countersSlice.actions;
export default countersSlice.reducer;
