export const aggGalleryUrl = (user) => {
    const defaultUrl = '/members';
    const menUrl = '/members/men';
    const girlsUrl = '/members/girls';


    // Default
    if (!user) {
        return girlsUrl;
    }

    // Default for connected user without preferences
    if (user && !user.galleryFilter) {
        return (user.sex === 'male') ? girlsUrl : menUrl;
    }

    if (user && user.galleryFilter) {
        if (user.galleryFilter.sexList.includes('female') && user.galleryFilter.sexList.includes('male')) {
            return defaultUrl;
        }

        return user.galleryFilter.sexList.includes('female') ? girlsUrl : menUrl;
    }
}
