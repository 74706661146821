import {STORAGE_CUSTOM_ADM} from "../config/Constants";

/**
 * Return tru if admin is browsing as customer logged in to user.
 * @returns {boolean}
 */
export const isCustomLogged = () => {
    if (typeof localStorage === 'undefined') {
        return false;
    }

    return localStorage.getItem(STORAGE_CUSTOM_ADM) !== null;
};

export const removeCustomMode = () => {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(STORAGE_CUSTOM_ADM);
    }
}

export const isDev = process.env.NODE_ENV === 'development';
export const isProduction = !isDev;

const safeMode = process.env.REACT_APP_SAFE_DEV_MODE;
export const publicDevMode = () => (isDev && (typeof safeMode !== "undefined" && safeMode === '1'));
