import Logo from "../../styles/media/logo.png";
import Classes from "./Header.module.scss";
import Button from "../../Components/Button/Button";

const Header = () => (
    <>
        <div className={Classes.Container}>
            <div className={Classes.Wrapper}>
                <img src={Logo} className={Classes.Logo} alt={"Travelfriend Logo"} />
                <Button fromHeader>Sign Up</Button>
            </div>
        </div>
        <div className={Classes.HeaderSpacer} />
    </>
);

export default Header;
