export const timeAgo = (timeStamp) => {
    var diffSeconds = Math.round((Date.now()-(timeStamp * 1000)) / 1000);

    if (diffSeconds >= 0 && diffSeconds < 60) {
        return 'Now';
    }
    else if (diffSeconds >= 60 && diffSeconds < 3600) {
        return Math.round(diffSeconds/60) + ' min';
    }
    else if (diffSeconds >= 3600 && diffSeconds < 86400) {
        return Math.round(diffSeconds/3600) + ' h';
    }
    else if (diffSeconds >= 86400 && diffSeconds < 604800) {
        return Math.round(diffSeconds/86400) + ' d';
    }
    else if (diffSeconds >= 604800 && diffSeconds < 2592000) {
        return Math.round(diffSeconds/604800) + ' wk';
    }
    else if (diffSeconds >= 2592000 && diffSeconds < 31536000) {
        return Math.round(diffSeconds/2592000) + ' mo';
    }
    else if (diffSeconds >= 31536000) {
        return Math.round(diffSeconds/31536000) + ' y';
    }

    return 'Now';
}

export const aggTripDate = (dateStr) => {
    let monthList = ['Jan','Feb', 'Mar','Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov','Dec'];
    let dt = dateStr.split('-');

    return monthList[parseInt(dt[1])-1] + ' ' + dt[2];
}

export const calculateAge = (date) => { 
    let dob = new Date(date);
    let diff_ms = Date.now() - dob.getTime();
    let age_dt = new Date(diff_ms); 
  
    return Math.abs(age_dt.getUTCFullYear() - 1970);
}

export const aggDateFromTimeStamp = (timeStamp) => {
    // convert unix timestamp to milliseconds
    var ts_ms = timeStamp * 1000;

    // initialize new Date object
    var date_ob = new Date(ts_ms);

    // year as 4 digits (YYYY)
    var year = date_ob.getFullYear();

    // month as 2 digits (MM)
    var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

    // date as 2 digits (DD)
    var date = ("0" + date_ob.getDate()).slice(-2);

    return (year + "-" + month + "-" + date);
}
