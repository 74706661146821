import {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import axios from "../../config/Axios";
import Autocomplete from "../UI/Autocomplete/Autocomplete";
import Loader from "../UI/Loader/Loader";
import Select from '../UI/Select/Select';
import classes from './FinishRegistration.module.scss';
import {updateUser} from "../../store/auth";
import {modalActions} from "../../store/modal";
import aggPhoto from '../../helpers/Photos';

const ageList = [{label: '', value: ''}];
for (let i = 18; i <= 99; i++) {
    ageList.push({label: i, value: i});
}

const FinishRegistration = (props) => {
    const dispatch = useDispatch();
    const [location, setLocation] = useState(null);
    const [photo, setPhoto] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPhoto, setIsLoadingPhoto] = useState(false);
    const user = useSelector(state => state.auth.user);

    const updateUserHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        let postData = {onboardingCompleted: 1};

        if (location) {
            postData.location = location;
        }
         
        if (event.target.name.value !== '') {
            postData.name = event.target.name.value;
        }

        if (event.target.birthday.value !== '') {
            postData.birthday = event.target.birthday.value;
        } 

        dispatch(updateUser(user.id, postData));
        // send welcome messages
        sendMessageResponse(true);
        dispatch(modalActions.close());
    }

    const uploadPhoto = (e) => {
        setError(null);
        if (isLoadingPhoto) {
            return;
        }

        setIsLoadingPhoto(true);
        let fd = new FormData();
        fd.append("photo", e.target.files[0]);

        axios.post("photos/" + user.id + "/upload?type=photo", fd, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((r) => {
                setTimeout(() => {
                    setPhoto(r.data);
                    setIsLoadingPhoto(false);
                }, 500);
            })
            .catch((error) => {
                showError(error.response.data.error);
                setIsLoadingPhoto(false);
            });
    }

    const deletePhoto = () => {
        axios.post('photos/'+user.id+'/'+photo.id+'/remove');
        setError(null);
        setPhoto(null);
    }
    
    const showError = (error) => {
        let errorText = 'Something went wrong. Please try again.';
        if (error === 'failure-format-size') errorText = 'Check photo format and size(up to 12MB). We only accept .png .jpg .gif format  pictures.';
        if (error === 'failure-size') errorText = 'This photo is too small. Please upload another one.';
        if (error === 'failure-exists') errorText = 'This photo is already uploaded. Please upload another one.';

        setError(errorText);
    }

    const onClose = () => {
        dispatch(updateUser(user.id, {onboardingCompleted: 1}));
        sendMessageResponse(false);
        dispatch(modalActions.close());
    }

    const sendMessageResponse = (sendWelcome) => {
        let postUrl = 'auth/send_unregistered_message/'+user.id;

        // send welcome email from admin
        if (sendWelcome) {
            postUrl = 'auth/send_unregistered_message/'+user.id+'/true';
        }

        // send message for girl and set response time
        axios.post(postUrl);
    }

    return (
        <div className={classes.Location}>
            <h1><i className="tg-icon icon-tick"></i>Your email has been verified</h1>
            <div className={classes.ContentContainer}>
                <p>Hey, You will be 5 times more likely to get an answer if you introduce yourself briefly.</p>
                <div className={classes.Rectangle}>
                    {!photo &&
                        <form className={classes.Upload}>
                            <div>
                                <i className="tg-icon icon-camera"></i>
                            </div>
                            {isLoadingPhoto ? (
                                <div className={classes.InputText}><Loader /></div>
                            ) : (
                                <div className={classes.InputText}>Add a Photo</div>
                            )}
                            {error &&
                                <div className={classes.ErrorText}>{error}</div>
                            }
                            <input
                                type="file"
                                accept="image/x-png,image/gif,image/jpeg"
                                name="photo"
                                className={classes.FileInput}
                                onChange={uploadPhoto}
                            />
                        </form>
                    }
                    {photo &&
                        <div className={classes.PhotoItem}>
                            <img
                                src={aggPhoto(photo.photo, 300, user)}
                                alt={""}
                            />
                            <div className={classes.DeletePhoto} onClick={deletePhoto}>
                                <i className="tg-icon icon-delete"></i>
                            </div>
                        </div>
                    }
                </div>
                <form className={classes.LocationFormn} onSubmit={updateUserHandler}>
                    <div className={classes.InputContainer}>
                        <div className={classes.InputLabel}>Your Name</div>
                        <input type="text" name="name" className={classes.InputValue} />
                    </div>
                    
                    <div className={classes.InputContainer}>
                        <div className={classes.InputLabel}>Your Age</div>
                        <Select name={'birthday'} list={ageList} cssOverride={'SelectRegistration'} />
                    </div>
                    
                    {!user.location.locationId && 
                    <div className={classes.InputContainer}>
                        <div className={classes.InputLabel}>Your home Location</div>
                        <Autocomplete onLocationSelect={setLocation} className={classes.AddLocationAutocomplete} />
                        <div className={classes.InputIcon}><i className="tg-icon icon-location"></i></div>
                    </div>
                    }
                    <div className={classes.SaveArea}>
                        <button type="submit" className={classes.Save} >
                        {isLoading ? <Loader white={true} /> : 'Update'}</button>
                        <button type="button" className={classes.Dismiss} onClick={onClose}>No, thanx</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default FinishRegistration;
